import dayjs from "dayjs";
import { getDuration, calcTotalRequest } from "../../utils/dateUtils";

function formatDuration(duration) {
  //return ( this.duration.as('hours') >= 24 ) ? this.duration.format('D:HH:mm') : this.duration.format('H:mm');
  return duration.asHours().toFixed(2);
}

class StaffTimes {
  constructor(workFunctions) {
    this.workFunctions = workFunctions;
    this.userTimes = [];
  }

  addUserTime(userTime) {
    userTime.workFunctions = this.workFunctions;
    this.userTimes.push(userTime);
  }

  get last() {
    return this.userTimes.at(-1);
  }

  get data() {
    return this.userTimes.map(el => el.data);
  }
}

class UserTimes {
  constructor(user) {
    this.user = user;
    this.allWorkFunctions = null; //Will be set by addUserTime in StaffTimes
    this.requests = [];
  }

  addRequest(req) {
    this.requests.push(req);
  }

  getDirectionRequests() {
    return this.requests.filter(el => el.requestType === 'direction' && el.isApproved);
  }

  getDirectionHours() {
    const duration = this.getDirectionRequests().reduce((sum, el) => sum.add(el.duration), dayjs.duration(0));
    return formatDuration(duration);
  }

  initFunctionHours(allWorkFunctions) {
    const obj = {};
    for (const wf of allWorkFunctions) {
      obj[wf.name] = dayjs.duration(0);
    }
    return obj;
  }

  getFunctionHours() {
    if (this.allWorkFunctions?.length === 0) {
      console.warn('No workfunctions loaded in stafftimes');
      return {};
    }
    const functionHours = this.initFunctionHours(this.allWorkFunctions);
    for (const req of this.requests) {
      if (req.userFunction && req.isApproved) {
        functionHours[req.userFunction.name] = functionHours[req.userFunction.name].add(req.duration)
      }
    }
    for (const key in functionHours) {
      functionHours[key] = formatDuration(functionHours[key]);
    }
    return functionHours;
  }

  get userId() { return this.user.id }
  get userName() { return this.user.name }

  set workFunctions(workFunctions) {
    this.allWorkFunctions = workFunctions;
  }

  get total() {
    return formatDuration(this.duration);
  }

  get pendingTotal() {
    return formatDuration(this.getDurationOfStatus('pending'));
  }

  get deniedTotal() {
    return formatDuration(this.getDurationOfStatus('denied'));
  }

  getDurationOfStatus(status) {
    return this.requests.filter(el => el.approval.status.name === status).reduce((sum, el) => sum.add(el.duration), dayjs.duration(0));
  }

  get duration() {
    return this.approvedRequests.reduce((sum, el) => sum.add(el.duration), dayjs.duration(0));
  }

  get approvedRequests() {
    return this.requests.filter(el => el.isApproved);
  }

  get data() {
    return {
      id: this.user.id,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      pending: this.pendingTotal,
      denied: this.deniedTotal,
      total: this.total,
      ...this.getFunctionHours(),
    }
  }
}

class TimeRequest {
  constructor(req) {
    this.req = req;

    this.approval = null;
  }

  setApproval(approval) {
    this.approval = approval;
  }

  get requestType() {
    return this.req.requestType.name;
  }

  get requestTypeId() {
    return this.req.requestType.id;
  }

  get userId() {
    return this.req.userId;
  }

  get start() {
    return this.req.startTime;
  }

  get end() {
    return this.req.endTime;
  }

  get total() {
    return this.duration.format('H:mm');
  }

  get userFunction() {
    return this.req.userFunction;
  }

  get workFunction() {
    return 'hi'
  }

  get duration() {
    const formattedDate = dayjs().format('YYYY-MM-DD');
    const t0 = dayjs(`${formattedDate} ${this.start}`, 'YYYY-MM-DDTHH:mm');
    let t1 = dayjs(`${formattedDate} ${this.end}`, 'YYYY-MM-DDTHH:mm');
    if (t1.isBefore(t0)) {
      t1 = t1.add(1, 'day');
    }

    return dayjs.duration(t1.diff(t0));
  }

  get isApproved() {
    return this.approval?.status.name === 'approved';
  }

  get isDenied() {
    return this.approval?.status.name === 'denied';
  }

  get isPending() {
    return this.approval?.status.name === 'pending';
  }

  get data() {
    return {
      start: this.start,
      end: this.end,
      total: this.total,
    }
  }
}

export { StaffTimes, UserTimes, TimeRequest };
export default UserTimes;