import React, {useState, useEffect, useContext} from 'react';
import {
  Box,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
  OutlinedInput,
  Skeleton,
} from '@mui/material';

//import WorkFunctionList from './WorkFunctionLIst';
import { WorkFunctionChip } from './WorkFunctionItem';

import * as api from '../../services/userAPI';
import { useTheme } from '@mui/material';
import {DefinitionsContext} from "../../context/DefinitionsContext";


//TODO: Replace api.listUserfunctions with usecontext definitions

function WorkFunctionInput({value, onChange}) {
    const { translationList } = useContext(DefinitionsContext);
  const theme = useTheme()
  const [allFuns, setAllFuns] = useState([]);

  useEffect( () => {
    async function fetchWorkFunctions() {
      const all = (await api.listUserFunctions()).data;
      setAllFuns(all.map( el => el.name));
    }
    fetchWorkFunctions();
  }, []);

  if (allFuns.length === 0) return <Skeleton variant="rectangular" width={200} height={40} />

  return (
    <FormControl fullWidth>
      <InputLabel>{translationList.function}</InputLabel>
      <Select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        input={<OutlinedInput label={translationList.function}/>}
        renderValue={ selected => (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0.5,
            }}
          >
            <WorkFunctionChip workFunction={selected} />
          </Box>
        )}
      >
          {allFuns.map( el => {
          return <MenuItem value={el} key={el} style={{fontWeight: theme.typography.fontWeightRegular}}>
            <WorkFunctionChip workFunction={el}/>
          </MenuItem>
        })}
      </Select>
    </FormControl>
  );
}

export { WorkFunctionInput }
export default WorkFunctionInput;