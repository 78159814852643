import { ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

const boxOutline = {
  border: 2,
  borderColor: '#8888',
  borderRadius: '10px',
}

const sxModal = {
  position: 'absolute',
  minHeight: '200px',
  minWidth: '300px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: 4,
  borderRadius: '20px',
};

const backdropProp = {
  backdrop: {
    style: {
      backgroundColor: 'rgba(0,0,0,0.01)',
      backdropFilter: 'blur(8px)',
    }
  }
};

export { backdropProp, sxModal, boxOutline }