import { api, defaultCallback } from './api';

async function getPosts(topicId=null, startNbr=null, endNbr=null, userId=null, cb=defaultCallback) {
  const res = await api.get(
    `feed/post`,
    {
      params: {
        ...(topicId  !== null && { topicId  }),
        ...(startNbr !== null && { startNbr }),
        ...(endNbr   !== null && { startNbr }),
        ...(userId   !== null && { userId   })
      }
    }
  );

  cb(res);
  return res;
}

async function addPost(topic, title, content, tags=[], cb=defaultCallback) {
  const res = await api.post(
    `feed/post`,
    {
      topic: topic,
      title: title,
      content: content,
      tags: tags,
    }
  )

  cb(res);
  return res;
}

async function addComment(postId, comment, cb=defaultCallback) {
  const res = await api.post(
    `feed/comment/`,
    {
      postId: postId,
      comment: comment,
    }
  );

  cb(res);
  return res;
}

async function updatePost(postId, title, content, tags=[], cb=defaultCallback) {
  const res = await api.put(
    `feed/post/${postId}`,
    {
      title: title,
      content: content,
      tags: tags,
    }
  );

  cb(res);
  return res;
}

async function deletePost(postId, cb=defaultCallback) {
  const res = await api.delete(`feed/post/${postId}`);
  cb(res);
  return res;
}

async function getComments(postId, author=null, cb=defaultCallback) {
  const res = await api.get(
    `feed/comment`,
    {
      params: {
        postId: postId,
        ...(author !== null && {createdBy: author}),
      }
    }
  );

  cb(res);
  return res;
}

export {
  getPosts,
  addPost,
  addComment,
  getComments,
  updatePost,
  deletePost,
};