import { useState, useEffect } from 'react';

function useViewportHeight() {
    const [vh, setVh] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setVh(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return vh;
}

export default useViewportHeight;