import { useCallback, useState, useEffect } from 'react';

function useAreInputsValid() {
  const [areInputsValid, setAreInputsValid] = useState(true);
  const [inputs, setInputs] = useState({});

  const onValidChange = useCallback((inputName, isValid) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [inputName]: {
        ...prevInputs[inputName],
        isValid,
      },
    }));
  }, []);

  useEffect(() => {
    if (Object.keys(inputs).length === 0) { setAreInputsValid(true); return; }
    const areInputsValid = Object.values(inputs).every((input) => input.isValid);
    setAreInputsValid(areInputsValid);
  }, [inputs]);

  return [areInputsValid, onValidChange];
}

export default useAreInputsValid;
export { useAreInputsValid };