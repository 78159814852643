import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  IconButton,
  FormControl,
  OutlinedInput,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

//import WorkFunctionList from './WorkFunctionLIst';
import { WorkFunctionChip } from './WorkFunctionItem';

import * as api from '../../services/userAPI';
import { useTheme } from '@mui/material';
import { DefinitionsContext } from '../../context/DefinitionsContext';


//TODO: Replace api.listUserfunctions with usecontext definitions

function MultipleWorkFunctionInput({value, onChange}) {
  const { workFunctions } = useContext(DefinitionsContext);
  const theme = useTheme()

  //if (!value) return <Box />

  return (
    <FormControl fullWidth>
      <InputLabel>Function</InputLabel>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
      <Select
        fullWidth
        value={value}
        onChange={ (e) => onChange([...value, e.target.value]) }
        input={<OutlinedInput label='Function'/>}
        renderValue={ selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, }} >
            {selected.map( el => <WorkFunctionChip key={el.id} workFunction={el.name} /> )}
          </Box>
        )}
      >
        {workFunctions.filter( el => !value.map(val => val.id).includes(el.id)).map( el => {
          return <MenuItem
            value={el}
            key={el.id}
            style={{fontWeight: theme.typography.fontWeightRegular}}
          >
            <WorkFunctionChip key={el.id} workFunction={el.name}/>
          </MenuItem>
        } )}
      </Select>
      <IconButton
        onClick={ () => onChange([])}
        sx={{
          width: '60px',
          height: '60px',
        }}
      >
        <HighlightOffIcon />
      </IconButton>
      </Box>
    </FormControl>
  );
}

export { MultipleWorkFunctionInput }
export default MultipleWorkFunctionInput;