import dayjs from 'dayjs';

import { dayjsToTimeString } from "../../utils/dateUtils";
import { v4 as uuidv4 } from 'uuid';

/*
assignee.status indicates how the local state in the frontend relates to the backend.
Possible values: original, new, modified, undefined
*/

//TODO: Need to make an assignee id independent from userId and eventUserId
function createAssigneePlaceholder(funName, startTime, endTime, userId) {
  const assignee = new Assignee(
    funName,
    null,
    dayjsToTimeString(startTime),
    dayjsToTimeString(endTime)
  )

  assignee.status = 'new';
  assignee.shouldUpdate = true;

  return assignee;
}

// Translate eventUser from backend to assignees
function eventUserToAssignee(eventUser) {
  const assignee = new Assignee(
    eventUser.userFunctionName,
    eventUser.userFunctionId,
    eventUser.startTime,
    eventUser.endTime,
    eventUser.responsibilityTypeName,
    eventUser.eventUserId,
    eventUser.userId,
    null,
    eventUser.isEffectiveEventUser,
  )

  assignee.status = 'original';

  return assignee;
}

function userToAssignee(user, event) {
  const funName = (user.userFunctions.length === 0) ? 'door' : user.userFunctions[0].name;
  const assignee = new Assignee(
    funName,
    user.userFunctionId,
    event.startTime,
    event.endTime,
    null,
    null,
    user.id,
    user,
  )

  assignee.status = 'new';
  assignee.shouldUpdate = true;

  return assignee;
}

class Assignee {
  constructor(
    userFunctionName,
    userFunctionId,
    startTime,
    endTime,
    responsibility=null,
    eventUserId=null,
    userId=null,
    user=null,
    isFinal=false
  ) {
      this.userFunctionName = userFunctionName;
      this.userFunctionId   = userFunctionId; //TODO: Get this automatically
      this.startTime        = startTime;
      this.endTime          = endTime;
      this.responsibility   = responsibility;
      this.userId           = userId;
      this.eventUserId      = (eventUserId) ? eventUserId : uuidv4();
      this.responsibility   = responsibility;

      this.user             = (user) ? user : null;
      this.isFinal          = isFinal;
      this.status           = 'undefined';
      this.shouldUpdate     = false;
      this.id               = uuidv4();
  }

  update() {
    this.status = (this.status === 'new') ? 'new' : 'modified';
    this.shouldUpdate = true;
  }

  setStartTime(newTime) {
    this.startTime = newTime;
    this.update();
  }

  setEndTime(newTime) {
    this.endTime = newTime;
    this.update();
  }

  setResponsibility(newResponsibility) {
    this.responsibility = newResponsibility;
    this.update();
  }

  setUser(user) {
    this.user = user;
    this.userId = user.id;
    this.update();

    return this;
  }

  getRequest() {
    if (this.status === 'new') {
      return {
        userId: this.userId,
        userFunctionName: this.userFunctionName,
        responsibilityType: this.responsibility,
        startTime: this.startTime,
        endTime: this.endTime,
      };
    } else if (this.status === 'modified') {
      return {
        userId: this.userId,
        eventUserId: this.eventUserId,
        userFunctionName: this.userFunctionName,
        responsibilityType: this.responsibility,
        startTime: this.startTime,
        endTime: this.endTime,
      };
    } else {
      return null;
    }
  }
}

export default Assignee;
export { Assignee, createAssigneePlaceholder, userToAssignee, eventUserToAssignee };