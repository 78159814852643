import { api, defaultCallback } from './api';

async function createEvent(name, eventDate, startTime, endTime, eventType, cb = defaultCallback) {
  const res = await api.post(
    `event`,
    {
      name: name,
      eventDate: eventDate.format('YYYY-MM-DD'),
      startTime: startTime.format('HH:mm'),
      endTime: endTime.format('HH:mm'),
      eventCategory: eventType,
    }
  )
  cb(res);

  return res;
}

async function updateEvent(eventId, name, eventDate, startTime, endTime, eventType, cb = defaultCallback) {
  const res = await api.put(
    `event/${eventId}`,
    {
      name: name,
      eventDate: eventDate.format('YYYY-MM-DD'),
      startTime: startTime.format('HH:mm'),
      endTime: endTime.format('HH:mm'),
      eventCategory: eventType,
    }
  )
  cb(res);

  return res;
}

async function getEvent(eventId, cb = defaultCallback) {
  const res = await api.get(
    `event/${eventId}`
  );
  cb(res);

  return res?.data;
}

function getAllEvents(cb = defaultCallback) {
  api.get(`event/`).then(res => cb(res?.data));
}

async function getEventsBetweenDates(startDate, endDate, cb = defaultCallback) {
  const res = await api.get(
    `event/getEventsBetweenDates`,
    {
      params: { startDate: startDate, endDate: endDate }
    }
  )
  cb(res?.data);

  return res?.data;
}


function deleteEvent(eventId, cb = defaultCallback) {
  api.delete(
    `event/${eventId}`
  ).then(res => cb(res?.data))
};

async function addAssignees(eventId, assignees, isActual = false, cb = defaultCallback) {
  if (assignees.length > 0) {
    assignees.map(el => (!el.responsibilityType) ? console.warn('No responsibility type:', el) : null);
  }
  try {
    const res = await api.put(
      `eventUser`,
      assignees,
      {
        params: { eventId: eventId, isEffectiveEventUsers: isActual }
      },
    );
    cb(res);

    return res;
  } catch (e) {
    throw e;
  }
}

async function deleteAssignees(eventUserIds, cb = defaultCallback) {
  const res = await api.delete(
    `eventUser`,
    {
      data: eventUserIds
    },
  );
  cb(res);

  return res;
}

async function getAllEventCategories(cb = defaultCallback) {
  const res = await api.get(
    `event/getEventCategories/`
  );
  cb(res);

  return res;
}

async function getAllEventResponsibilityTypes(cb = defaultCallback) {
  const res = await api.get(
    `eventUser/getAllEventResponsibilityTypes/`
  );
  cb(res);

  return res;
}

export {
  createEvent,
  updateEvent,
  getEvent,
  getAllEvents,
  getEventsBetweenDates,
  deleteEvent,
  addAssignees,
  deleteAssignees,
  getAllEventCategories,
  getAllEventResponsibilityTypes,
}