import { v4 as uuidv4 } from 'uuid';

function loadTicket(ticketObj) {
  const ticket    = new Ticket(ticketObj.eventId);
  ticket.typeId   = ticketObj.ticketTypeId;
  ticket.id       = ticketObj.id;
  ticket.price    = ticketObj.price;
  ticket.quantity = ticketObj.quantity;
  ticket.start    = ticketObj.startingNumber;
  ticket.end      = ticketObj.closingNumber;
  ticket.status   = 'original';

  return ticket;
}

class Ticket {
  constructor(eventId, typeId) {
      this.eventId = eventId;
      this.typeId  = typeId;
      this._price  = 0;
      this._start  = 1;
      this._end    = 1;

      this._quantity = this.end - this.start;
      this.id = uuidv4();
      this.status = 'new'; //Attention
    }

    update() {
      this.status = (this.status === 'new') ? 'new' : 'modified';
    }

    calcQuantity() {
      if (!this._end || !this._start) {return}
      this._quantity = this._end - this._start;
    }

    get price() {
      return this._price;
    }
    set price(val) {
      this._price = val;
      this.update();
    }

    get start() {
      return this._start;
    }
    set start(val) {
      this._start = val;
      this.update();
      this.calcQuantity();
    }

    get end() {
      return this._end;
    }
    set end(val) {
      this._end = val;
      this.update();
      this.calcQuantity();
    }

    get quantity() {
      return this._quantity;
    }
    set quantity(val) {
      this._quantity = val;
      this.update();
    }

    get total() {
      return this._quantity * this.price;
    }

    get json() {
      return {
        price         : this.price,
        startingNumber: this.start,
        closingNumber : this.end,
        quantity      : this.quantity,
        ticketTypeId  : this.typeId,
        eventId       : this.eventId,
      }
    }
}

export { loadTicket, Ticket }
export default Ticket;