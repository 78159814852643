import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import 'dayjs/locale/en-gb'; // Import the English locale
import 'dayjs/locale/de-ch'; // Import the German locale
import 'dayjs/locale/fr-ch'; // Import the French locale


dayjs.extend(utc);
dayjs.extend(timezone);
let language='en-gb';
const setLocale = (languageId) => {
    switch (languageId) {
        case 1:
            dayjs.locale('en-gb'); // Set the locale to English
            language = 'en-gb';
            break;
        case 2:
            dayjs.locale('de-ch'); // Set the locale to German
            language = 'de-ch';
            break;
        case 3:
            dayjs.locale('fr-ch'); // Set the locale to French
            language = 'fr-ch';
            break;
        default:
            dayjs.locale('en-gb'); // Default to English if language ID is not recognized
            language = 'en-gb';
    }
};

const getConfiguredDayjs = (languageId) => {
    setLocale(languageId);
    return dayjs; // Return the configured dayjs instance
};

const getStringLanguage = (languageId) => {
    setLocale(languageId);
    return language; // Return the configured dayjs instance
};
export { getStringLanguage }
export default getConfiguredDayjs;