import React, { useState } from 'react';
import {
  Paper,
} from '@mui/material';

function ModalContainer({ children, sx }) {
  return (
    <Paper
      elevation={0}
      sx={{
        position: 'absolute',
        minHeight: '200px',
        minWidth: '300px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        padding: 4,
        borderRadius: '20px',
        ...sx,
      }}
    >
      {children}
    </Paper>
  );
}

function DialogContainer({ children, sx }) {
  return (
    <Paper
      elevation={0}
      sx={{
        minHeight: '200px',
        minWidth: '300px',
        boxShadow: 24,
        padding: 4,
        borderRadius: '20px',
        ...sx,
      }}
    >
      {children}
    </Paper>
  );

}

export { DialogContainer }
export default DialogContainer;