import { api, defaultCallback } from './api';

async function getUserTimes(userId, dateRange, cb=defaultCallback) {
  const res = api.get(
    `timeManagement/getByUserId`,
    {
      params: {userId: userId, startDate: dateRange[0], endDate: dateRange[1]}
    }
    );
  cb(res?.data);

  return res;
}

function getTimeRequest(requestId, cb=defaultCallback) {
  api.get(
    `timeManagement/${requestId}`
    ).then( res => cb(res?.data))
}

async function listTimeRequests(dateRange, cb=defaultCallback) {
  const res = await api.get(
    `timeManagement/`,
    {
      params: {startDate: dateRange[0], endDate: dateRange[1]}
    });
    cb(res?.data);
    return await res?.data;
}

function requestTime(date, startTime, endTime, userId, requestType, description, workFunctionId, cb=defaultCallback) {
  const body = {
      'requestType': requestType,
      //'eventDate'  : '2023-01-01',
      'date'       : date.format('YYYY-MM-DD'),
      'startTime'  : startTime.format('HH:mm'),
      'endTime'    : endTime.format('HH:mm'),
      'userFunctionId': workFunctionId,
      'userId'     : userId,
      'description': description,
    };
  api.post(
    `timeManagement`,
    body
  ).then( res => cb(res?.data) )
}

async function generateAllTimeRequests(eventId, cb=defaultCallback) {
  const res = await api.post(
    `timeManagement/generateAllTimeRequests/${eventId}`,
    {}
  );
  cb(res);

  return res;
}

export { listTimeRequests, getUserTimes, requestTime, getTimeRequest, generateAllTimeRequests }