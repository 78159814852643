import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Box,
  Paper,
  Button, IconButton, Menu, MenuItem, Typography,
} from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
  enUS, frFR, deDE,
  GridToolbarQuickFilter,
  useGridApiRef
} from '@mui/x-data-grid';
import * as XLSX from 'xlsx';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as api from '../../services';
import { initDateRange, formatDay, calcTotalRequest, monthFromDaterange } from '../../utils/dateUtils';
import { ApprovalIcon } from '../time/ApprovalItem';
import { DateRange } from '../../components/ui/buttons';
import { Loadable } from '../../components/wrapper/Loadable';
import { WorkFunctionAvatar } from '../work-functions/WorkFunctionItem';
import { DisplayDialog } from '../../components/ui/DisplayDialog';
import { StaffTimes, UserTimes, TimeRequest } from './TimeObjects';
import { DefinitionsContext } from "../../context/DefinitionsContext";
import { render } from '@testing-library/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { CurrentUserContext } from '../../context/CurrentUserContext';

function getLocaleText(languageId) {
  switch (languageId) {
    case 1:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
    case 2:
      return deDE.components.MuiDataGrid.defaultProps.localeText;
    case 3:
      return frFR.components.MuiDataGrid.defaultProps.localeText;
    default:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
  }
}

function CustomToolbar({ onExport }) {
  const { translationList } = useContext(DefinitionsContext);

  return (
      <GridToolbarContainer
          sx={{ display: 'flex',
            justifyContent: 'space-between' }}>
        {/* Default Toolbar */}
        <GridToolbar
            csvOptions={{ disableToolbarButton: true }}  // Disable CSV export
            printOptions={{ disableToolbarButton: true }}  // Disable Print export
        />

        {/* Export to Excel Icon + Text */}
        <Box
            onClick={onExport}
            sx={{
              display: 'flex',
              alignItems: 'center',  // Align icon and text vertically
              cursor: 'pointer',
              color: '#00695f',
              marginLeft: -1,
              paddingTop: 0.5,
              '@media (max-width:600px)': {
                paddingLeft: 1.5,
              },
            }}
        >
          <FileDownloadIcon sx={{ marginRight: 1, fontSize: 15 }} />
          <Typography
              variant="body1"
              sx={{
                fontSize: '10px',
                color: '#00695f',
                textTransform: 'uppercase',
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                '@media (max-width:600px)': {
                  fontSize: '10px',
                },
              }}
          >
            {translationList.export}  {/* Export text from translation */}
          </Typography>
        </Box>

        {/* Right side - Quick Filter */}
        <GridToolbarQuickFilter sx={{ marginLeft: 'auto' }} />
      </GridToolbarContainer>
  );
}

function TimeReport() {
  const { workFunctions, translationList } = useContext(DefinitionsContext);
  const isLoading = useRef(true);
  const [dateRange, setDateRange] = useState(initDateRange());
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (workFunctions?.length === 0) { return; }
    async function fetchData() {
      const times = await api.listTimeRequests(dateRange);
      const users = await api.listUsers();
      const approvals = (await api.getAllApprovals(dateRange[0], dateRange[1]))?.data;
      if (!times || !users || !approvals) { return; }

      const staffTimes = new StaffTimes(workFunctions);
      let timeRequest;
      times.sort((a, b) => a.userId - b.userId);
      const userIds = [...new Set(times.map(el => el.userId))];
      const userTimeMap = new Map();
      for await (let userId of userIds) {
        userTimeMap.set(userId, new UserTimes(await users.find(u => u.id === userId)));
      }

      let userTimes;
      for await (let req of times) {
        timeRequest = new TimeRequest(req);
        timeRequest.setApproval(approvals.find(a => a.timeRequestId === req.id));
        userTimes = userTimeMap.get(req.userId);
        userTimes.addRequest(timeRequest);
      }

      userTimeMap.forEach((userTime, userId) => { staffTimes.addUserTime(userTime) });

      setData(staffTimes.data);
      isLoading.current = false;
    }

    fetchData();
  }, [dateRange, workFunctions]);

  const timeWidth = 80;
  const columns = [
    { field: 'firstName', headerName: translationList.firstname },
    { field: 'lastName', headerName: translationList.lastname },
    { field: 'pending', headerName: translationList.pending, width: timeWidth },
    { field: 'denied', headerName: translationList.denied, width: timeWidth },
    { field: 'total', headerName: translationList.total, width: timeWidth },
    { field: 'direction', headerName: translationList.direction, width: timeWidth },
    { field: 'bar', headerName: translationList.bar, width: timeWidth },
    { field: 'door', headerName: translationList.door, width: timeWidth },
    { field: 'wardrobe', headerName: translationList.warderobe, width: timeWidth },
    { field: 'booking', headerName: translationList.booking, width: timeWidth },
    { field: 'register', headerName: translationList.register, width: timeWidth },
    { field: 'cleaning', headerName: translationList.cleaning, width: timeWidth },
    { field: 'light', headerName: translationList.light, width: timeWidth },
    { field: 'sound', headerName: translationList.sound, width: timeWidth },
    { field: 'accounting', headerName: translationList.accounting, width: timeWidth },
    { field: 'billing', headerName: translationList.billing, width: timeWidth },
    { field: 'advertising', headerName: translationList.advertising, width: timeWidth },
    { field: 'posters', headerName: translationList.posters, width: timeWidth },
    { field: 'manager', headerName: translationList.manager, width: timeWidth },
    { field: 'executive', headerName: translationList.executive, width: timeWidth },
  ];

  const { currentUser } = useContext(CurrentUserContext);
  const localeText = getLocaleText(currentUser.languageId);

  const exportToExcel = () => {
    const visibleRows = apiRef.current.getSortedRows();
    const visibleColumns = apiRef.current.getVisibleColumns();

    // Extract the data from visible rows and columns
    const exportData = visibleRows.map((row) => {
      const rowData = {};
      visibleColumns.forEach((col) => {
        rowData[col.headerName || col.field] = row[col.field];
      });
      return rowData;
    });

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'TimeReport');

    XLSX.writeFile(wb, `TimeReport-${monthFromDaterange(dateRange)}.xlsx`);
  };

  return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Paper elevation={2} sx={{ display: 'flex', justifyContent: 'space-between', padding: 1 }}>
          <DateRange onRangeChange={(t0, t1) => {
            setDateRange([t0, t1]);
            isLoading.current = true;
          }}
          />
        </Paper>
        <Loadable isLoading={isLoading.current}>
          <DataGrid
              apiRef={apiRef}
              localeText={localeText}
              rows={data}
              columns={columns}
              disableRowSelectionOnClick
              pageSizeOptions={[20, 50, 100]}
              density='compact'
              rowSelectionModel={selectedRows}
              onRowSelectionModelChange={selection => setSelectedRows(selection)}
              slots={{ toolbar: CustomToolbar }}
              slotProps={{
                toolbar: {
                  onExport: exportToExcel,
                  showQuickFilter: true,
                  },
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    approvalName: false,
                    userFunctionNames: false,
                    pending: false,
                    denied: false,
                  },
                },
                filter: {
                  filterModel: {
                    items: [{ field: 'approvalName', operator: 'contains', value: 'pending' }],
                  },
                },
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
          />
        </Loadable>
      </Box>
  );
}

export { TimeReport }
export default TimeReport;
