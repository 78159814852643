import { api, defaultCallback } from './api';

async function addEventPicture(eventId, image, cb=defaultCallback) {
  const res = await api.post(
    `mediaStorePicture/addEventPicture/${eventId}`,
    {
      image: image,
    }
  )
  cb(res);

  return res;
}

async function getEventPicture(eventId, cb=defaultCallback) {
  const res = await api.get(
    `mediaStorePicture/getEventPicture/${eventId}`
  )
  cb(res);

  return res;
}

async function addProfilePicture(userId, image, cb=defaultCallback) {
  const res = await api.post(
    `mediaStorePicture/addUserProfilePicture/${userId}`,
    {
      image: image,
    }
  )
  cb(res);

  return res;
}

async function getProfilePicture(userId, cb=defaultCallback) {
  const res = await api.get(
    `mediaStorePicture/getUserProfilePicture/${userId}`
  )
  cb(res);

  return res;
}

export {
  addEventPicture,
  getEventPicture,
  addProfilePicture,
  getProfilePicture,
}