import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridToolbar,
  enUS, frFR, deDE
} from '@mui/x-data-grid';
import { WorkFunctionAvatar } from '../work-functions/WorkFunctionItem';
import { filterArchived } from '../../utils';

import * as userAPI from '../../services/userAPI';
import { DefinitionsContext } from "../../context/DefinitionsContext";
import { CurrentUserContext } from "../../context/CurrentUserContext";

function getLocaleText(languageId) {
  switch (languageId) {
    case 1:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
    case 2:
      return deDE.components.MuiDataGrid.defaultProps.localeText;
    case 3:
      return frFR.components.MuiDataGrid.defaultProps.localeText;
    default:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
  }
}

function Contacts() {
  const [users, setUsers] = useState([]);
  const { translationList } = useContext(DefinitionsContext);

  const [columns, setColumns] = useState([
    { field: 'firstName', headerName: translationList.firstname, width: 150 },
    { field: 'lastName', headerName: translationList.lastname, width: 150 },
    { field: 'userFunctions', headerName: translationList.function, width: 110, renderCell: params => <WorkFunctionAvatar workFunctions={params.row.userFunctions} max={3} /> },
    { field: 'userFunctionNames', headerName: translationList.functionName, width: 110, valueGetter: params => params.row.userFunctions.map(el => el.name) },
    { field: 'mobileNumber', headerName: translationList.mobileNumber, width: 150 },
    { field: 'email', headerName: translationList.email, width: 150 },
    { field: 'phoneNumber', headerName: translationList.phoneNumber, width: 150 },
  ]);

  useEffect(() => {
    (async () => {
      const res = await userAPI.listUsers();
      if (!res) { return; }
      setUsers(filterArchived(res));
    })();
  }, []);

  const { currentUser } = useContext(CurrentUserContext);
  const localeText = getLocaleText(currentUser.languageId);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setColumns([
          { field: 'firstName', headerName: translationList.firstname, width: 90 },
          { field: 'lastName', headerName: translationList.lastname, width: 90 },
          { field: 'userFunctions', headerName: translationList.function, width: 150, renderCell: params => <WorkFunctionAvatar workFunctions={params.row.userFunctions} max={3} /> },
          { field: 'userFunctionNames', headerName: translationList.functionName, width: 80, valueGetter: params => params.row.userFunctions.map(el => el.name) },
          { field: 'mobileNumber', headerName: translationList.mobileNumber, width: 100 },
          { field: 'email', headerName: translationList.email, width: 180 },
          { field: 'phoneNumber', headerName: translationList.phoneNumber, width: 100 },
        ]);
      } else {
        setColumns([
          { field: 'firstName', headerName: translationList.firstname, width: 150 },
          { field: 'lastName', headerName: translationList.lastname, width: 150 },
          { field: 'userFunctions', headerName: translationList.function, width: 110, renderCell: params => <WorkFunctionAvatar workFunctions={params.row.userFunctions} max={3} /> },
          { field: 'userFunctionNames', headerName: translationList.functionName, width: 110, valueGetter: params => params.row.userFunctions.map(el => el.name) },
          { field: 'mobileNumber', headerName: translationList.mobileNumber, width: 150 },
          { field: 'email', headerName: translationList.email, width: 180 },
          { field: 'phoneNumber', headerName: translationList.phoneNumber, width: 160 },
        ]);
      }
    };

    // Initial check
    handleResize();

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [translationList]);

  return (
      <Box
          sx={{
            height: '100%',
            width: '100%',
            '@media (max-width:600px)': {
              paddingRight: 1,
              paddingLeft: 1,
            },
          }}
      >
        <DataGrid
            sx={{
              '@media (max-width:600px)': {
                border: 'none'
              },
            }}
            localeText={localeText}
            rows={users}
            columns={columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  userFunctionNames: false,
                  phoneNumber: false,
                  email: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[20]}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
              },
            }}
        />
      </Box>
  );
}

export { Contacts }
export default Contacts;
