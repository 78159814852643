
import React, { useRef } from 'react';
import {
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialoogActions,
  DialogContentText,
} from '@mui/material';
import { useTheme } from '@mui/material';

import { DialogCloseButton } from '../buttons';

function StaticWindow({ title, isOpen, onClose, children, hideBackdrop=false, sx={} }) {
  const { container: {dialog} } = useTheme();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      hideBackdrop={hideBackdrop}
      PaperProps={dialog.paper}
      slotProps={dialog.slotProps}
      fullWidth
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 30px',
        }}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogCloseButton onClose={onClose}/>
      </Box>
      <DialogContent
        sx={{
          marginTop: title ? '-20px' : '-40px',
        }}
      >
        {children} 
      </DialogContent>
    </Dialog>
  );
}

export { StaticWindow }
export default StaticWindow;