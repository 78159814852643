import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Tab,
  Tabs,
} from '@mui/material';
import PriorityIcon from '@mui/icons-material/PriorityHigh';

import { CurrentUserContext } from '../../context/CurrentUserContext';
import PersonalDataService from './PersonalDataService';
import BankingInformation from './BakingInformation';
import { checkIsComplete as checkPersonalComplete } from './PersonalDataService';
import { checkIsComplete as checkBankingComplete } from './BakingInformation';
import Notification from '../../components/ui/Notification';
import { TabPanel } from '../../components/ui/containers';
import * as userAPI from '../../services/userAPI';
import {DefinitionsContext} from "../../context/DefinitionsContext";

function PersonalDataPage() {
  const { currentUser } = useContext(CurrentUserContext);
  return (
    <PersonalData userId={currentUser.id} />
  );
}

function PersonalData({ userId, disabled = false }) {
  const { translationList } = useContext(DefinitionsContext);
  const { currentUser } = useContext(CurrentUserContext);
  const [activeTab, setActiveTab] = useState(0);
  const [notification, setNotification] = useState({ text: '', severity: 'info' });
  const [modalNotify, setModalNotify] = useState(false);
  const [user, setUser] = useState(userId ? null : currentUser);
  const [isComplete, setIsComplete] = useState({ 'personal': false, 'banking': false });

  useEffect(() => {
    if (userId) {
      (async () => {
        const user = await userAPI.getUser(userId);
        if (!user) return;
        setUser(user);
      })();
    }
  }, [userId]);

  useEffect(() => {
    if (notification.text) {
      setModalNotify(true);
    }
  }, [notification]);

    useEffect(() => {
        if (user) {
            (async () => {
                const res = await userAPI.getUser(userId);
                if (res?.data) {
                    const isPersonalComplete = checkPersonalComplete(res.data);
                    setIsComplete(prev => ({ ...prev, personal: isPersonalComplete }));
                }
            })();
        }
    }, [user, userId]);

    useEffect(() => {
        if (user) {
            (async () => {
                const res = await userAPI.getUserBankAccount(userId);
                if (res?.data) {
                    const isBankingComplete = checkBankingComplete(res.data[0]);
                    setIsComplete(prev => ({ ...prev, banking: isBankingComplete }));
                }
            })();
        }
    }, [user, userId]);

  return (
    <Box
        sx={{
            '@media (max-width:600px)': {
                paddingLeft: 1,

            }
        }}>
      <Notification
        isOpen={modalNotify}
        onClose={() => setModalNotify(false)}
        text={notification.text}
        severity={notification.severity}
      >
      </Notification>
      <Tabs
        value={activeTab}
        onChange={(e, val) => setActiveTab(val)}
      >
        <Tab
          icon={isComplete.personal ? null : <PriorityIcon sx={{ color: 'red' }}/>}
          iconPosition='start'
          label={translationList.identity}
        />
        <Tab
          icon={isComplete.banking ? null : <PriorityIcon sx={{ color: 'red' }}/>}
          iconPosition='start'
          label={translationList.salary}
          />
      </Tabs>
        <TabPanel value={activeTab} index={0}>
            <PersonalDataService
                userId={userId}
                disabled={disabled}
                setNotification={setNotification}
                setIsComplete={(isCompleteResult) =>
                    setIsComplete(prev => ({ ...prev, 'personal': isCompleteResult }))
                } // Use the actual result from checkIsComplete
            />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
            <BankingInformation
                userId={userId}
                disabled={disabled}
                setIsComplete={(isCompleteResult) =>
                    setIsComplete(prev => ({ ...prev, 'banking': isCompleteResult }))
                } // Use the actual result from checkIsComplete
                setNotification={setNotification}
            />
        </TabPanel>

    </Box>
  );
}

export default PersonalDataPage;
export { PersonalData, PersonalDataPage };