import React, { useContext } from 'react';
import {
  Box,
  List,
  ListItemButton,
  Divider,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { CurrentUserContext } from '../../context/CurrentUserContext';
import { UserAvatar } from '../users/UserAvatar';
import { logout } from '../../services/authAPI';
import {DefinitionsContext} from "../../context/DefinitionsContext";

function Settings({ onClose }) {
  const navigate = useNavigate();
  const {currentUser, setCurrentUser} = useContext(CurrentUserContext);
  const { translationList } = useContext(DefinitionsContext);

  function handleLogout() {
    setCurrentUser(undefined);
    logout();
  }

  return (
      <List>
        <Box sx={{display: 'flex', alignItems: 'center', gap: 1, pb:2, ml: 2}}>
          <UserAvatar user={currentUser}/>
          <Typography variant='h6'>{`${currentUser.firstName} ${currentUser.lastName}`}</Typography>
        </Box>
        <Divider />

        <ListItemButton
          onClick={() => {navigate('/settings'); onClose();}}
        >
            {translationList.settings}
        </ListItemButton>
        <ListItemButton onClick={handleLogout}>{translationList.logout}</ListItemButton>
      </List>
  );
}

export { Settings }
export default Settings;