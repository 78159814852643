import axios from 'axios';

axios.defaults.withCredentials = true;

const defaultCallback = (res) => {};

const api = axios.create({
  baseURL: 'https://isc-time.ch/api/',
  withCredentials: true,
});

export default api;
export { api, defaultCallback };