import React, { useState } from 'react';
import {
  Box,
  List,
  Paper,
  Divider,
  Typography,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { getUser } from '../../services/userAPI';

import AssigneeItem from './AssigneeItem';
import StaffSelect from './AssigneeSelect';
import { Window, SecondaryWindow } from '../../components/ui/windows';

function FunctionPicker({ users, setUsers, functionName }) {
  const [isAddDialog, setIsAddDialog] = useState();

  function addUser(user) {
    setUsers([...users, user]);
  }

  return (
    <Box>
      <SecondaryWindow
        isOpen={isAddDialog}
        onClose={ () => setIsAddDialog(false) }
        hideBackdrop={true}
      >
        <StaffSelect
          onSelect={ selectedUser => {
            addUser(selectedUser);
            setIsAddDialog(false);
          }}
        />
      </SecondaryWindow>
      <List>
        <Typography>{functionName}</Typography>
        <Divider sx={{marginBottom: 1}}/>
        {users.map( el => <AssigneeItem user={el} /> )}
      </List>
      <Box
        display='flex'
        justifyContent='flex-end'
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            margin: 0,
          }}
        >
          <IconButton
            size='large'
            color='secondary'
            onClick={ () => setIsAddDialog(true) }
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default FunctionPicker;