import { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
} from '@mui/material';
import { CurrentUserContext } from '../../context/CurrentUserContext';
import { updatePost } from '../../services';
import { TextInput, useAreInputsValid } from '../../components/inputs';
import {DefinitionsContext} from "../../context/DefinitionsContext";

function PostEditor({ defaultPost, onClose, onChange }) {
  const { currentUser } = useContext(CurrentUserContext);
  const [title, setTitle]     = useState(defaultPost?.title || '');
  const [content, setContent] = useState(defaultPost?.content || '');
  const [funs, setFuns]       = useState(currentUser.userFunctions);
  const [isValid, onValidChange] = useAreInputsValid();
  const { translationList } = useContext(DefinitionsContext);

  async function handleSubmit() {
    if (!title && !content) {return;}
    await updatePost(defaultPost?.id, title, content, funs.map( el => el.name ));
    onChange();
    onClose();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <TextInput
        isRequired
        label={translationList.title}
        value={title}
        onChange={ e => setTitle(e.target.value) }
      />
      <TextInput
        label={translationList.content}
        multiline
        fullWidth
        rows={4}
        isRequired
        max={3000}
        value={content}
        onChange={ e => setContent(e.target.value) }
        onValidChange={onValidChange}
      />
      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button
          disabled={!isValid}
          variant='contained'
          onClick={handleSubmit}
        >
          {translationList.toPost}
        </Button>
      </Box>
    </Box>
  )
}

export default PostEditor;
export { PostEditor };