import { PersonalData } from './PersonalData';
import { StaticWindow } from '../../components/ui/windows';

import React, { useState } from 'react';
import {
  Box,
  Typography,
} from '@mui/material';

function PersonalDataPopup({userId, isOpen, onClose, disabled=false}) {
  return (
    <StaticWindow
      title='Personal Data'
      isOpen={isOpen}
      onClose={onClose}
    >
      <PersonalData userId={userId} disabled={disabled}/>
    </StaticWindow>
  );
}

export { PersonalDataPopup }
export default PersonalDataPopup;