import { useContext } from 'react';
import { CurrentUserContext } from '../../context/CurrentUserContext';

const levelMap = new Map([
  ['ADMIN', 9],
  ['EXECUTIVE', 7],
  ['MANAGER', 5],
  ['EMPLOYEE', 3],
]);

function hasPermission(level, currentUser) {
  return levelMap.get(currentUser.account?.roleName) >= level;
}

function isSameOrHigherRole(roleName, currentUser) {
  return levelMap.get(currentUser.account?.roleName) <= levelMap.get(roleName);
}

function isSameOrHigherRoleWithStupidAdminOverwrite(roleName, currentUser) {
  if (currentUser.account?.roleName === 'ADMIN') {
    return false;
  } else {
    return isSameOrHigherRole(roleName, currentUser);
  }
}

function Permission({ children, level }) {
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <div>
      {hasPermission(level, currentUser) &&
        children
      }
    </div>
  )
}

export default Permission;
export { hasPermission, Permission, isSameOrHigherRole, isSameOrHigherRoleWithStupidAdminOverwrite };