import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Paper,
  Box,
  TextField,
} from '@mui/material';
import { TextInput } from '../inputs/TextInput';

function FormTextInput({ name, control, label, defaultValue, onValideChange, disabled, digitsOnly, isRequired, max=255, type = 'text', sx = {} }) {
  return (
    <Box
      sx={{
        flex: 1,
        ...sx,
      }}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          validate: (value) => {
            if (value.length > max) {
              if (onValideChange) {onValideChange(name, false)};
              return `Value should be at most ${max} characters`;
            }
            else if (isRequired && value.length === 0) {
              if (onValideChange) {onValideChange(name, false)}
              return 'This field is required';
            }
            else if (digitsOnly && !/^\d+$/.test(value)) {
              if (onValideChange) {onValideChange(name, false)}
              return 'This field should contain only digits';
            }
            if (onValideChange) {onValideChange(name, true)}
            return true;
          }
        }}
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => (
          <TextField
            helperText={error ? error.message : null}
            error={!!error}
            onChange={onChange}
            value={value}
            fullWidth
            label={label}
            variant='outlined'
            type={type}
            disabled={disabled}
          />
        )
        }
      />
    </Box>
  )
}

export { FormTextInput };
export default FormTextInput;
