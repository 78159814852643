import { api, defaultCallback } from './api';

async function getAllTranslation(languageId, cb=defaultCallback) {
  const res = await api.get(
      `translation?languageId=${languageId}`,
  );
  cb(res);
  return res?.data;
}

async function getAllLanguages(cb=defaultCallback) {
  const res = await api.get(
      `translation/languages`,
  );

  cb(res);
  return res?.data;
}

async function changeUserLanguage(userId, languageId, cb=defaultCallback) {
  const res = await api.put(
      `user/${userId}`,
      { languageId: languageId }
  );

  cb(res);
  return res;
}

export {
  getAllLanguages,
  getAllTranslation,
  changeUserLanguage,
}