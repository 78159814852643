import React, { useState, useEffect, Fragment, useContext } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import {
  Box,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import dayjs from 'dayjs';

import AssigneePicker from '../assignee/AssigneePicker';
import { backdropProp, sxModal } from '../../styles/styles';
import { StaticWindow } from '../../components/ui/windows';
import { ActionDial } from '../../components/ui/buttons';
import { Notification } from '../../components/ui/Notification';
import ConfirmationDialog from '../../components/boilerplate/ConfirmationDialog';
import { ImageUpload } from '../../components/inputs/ImageUpload';
import * as api from '../../services';

import { EventContext } from '../../context/EventContext';
import { DefinitionsContext } from '../../context/DefinitionsContext';
import { eventUserToAssignee } from '../assignee/AssigneeObject';
import { stringToDayjs } from '../../utils/dateUtils';
import { Permission } from '../../components/wrapper/Permission';
import { eventConflictMessage } from '../../utils/eventConflict';
import { TextInput, useAreInputsValid } from '../../components/inputs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

function EventEditor({ createMode = false, isOpen, eventId = null, onClose, onUpdate, children }) {
  const currentDate = dayjs();
  const { eventCategories } = useContext(DefinitionsContext);
  const { translationList } = useContext(DefinitionsContext);
  const [isValid, onValidChange] = useAreInputsValid();

  // Data
  const [event, setEvent] = useState(null);
  const [eventUsers, setEventUsers] = useState(null);
  const [title, setTitle] = useState('');
  const [date, setDate] = useState(currentDate);
  const [startTime, setStartTime] = useState(currentDate.set('hour', 23).set('minute', 0));
  const [endTime, setEndTime] = useState(currentDate.set('hour', 5).set('minute', 0))
  const [assignees, setAssignees] = useState(null); //local state of eventUsers
  const [notify, setNotify] = useState({ text: '', severity: 'info' });
  const [image, setImage] = useState(null);
  const [eventType, setEventType] = useState('')
  const [hideActions, setHideActions] = useState([]);

  // Dialogs
  const [showNotify, setShowNotify] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showUnsaved, setShowUnsaved] = useState(false);

  // State
  const [isDisabled, setIsDisabled] = useState(true);
  const [isPlanMode, setIsPlanMode] = useState(createMode);
  const [isSaving, setIsSaving] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [hasNewImage, setHasNewImage] = useState(false);
  const [updateCounter, setUpdateCounter] = useState(0);

  /* --Functions------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  // Checks if user should be warned about unsaved changes
  function handleClose() {
    const isMutated = !(assignees.map(el => el.status).every(val => val === 'original'));
    const isDeletion = (findDeletions().length !== 0);
    if (isMutated || isDeletion) {
      setShowUnsaved(true);
    } else {
      close();
    }
  }

  // Resets states and closes dialog
  function close() {
    resetState();
    onClose();
  }

  function handleImageUpload(newImage) {
    setHasNewImage(true);
    setImage(newImage);
  }

  // Reset all states
  function resetState() {
    setEvent(null);
    setEventUsers(null);
    setTitle('');
    setDate(currentDate);
    setStartTime(currentDate.set('hour', 23).set('minute', 0));
    setEndTime(currentDate.set('hour', 5).set('minute', 0));
    setAssignees(null);
    setNotify({ text: '', severity: 'info' });
    setImage(null);
    setEventType('');
    setHideActions([]);

    setShowNotify(false);
    setShowDelete(false);
    setShowUnsaved(false);

    setIsDisabled(true);
    setIsPlanMode(true);
    setIsSaving(false);
    setHasNewImage(false);
  }

  // Trigger creation of Event
  function handleCreate() {
    if (!title) {
      setNotify({ text: translationList.mustProvideTitle, severity: 'error' })
      setShowNotify(true);
      return
    }
    setIsCreating(true);
  }

  // Check if any delete eventUser calls are needed when saving an edited event
  function findDeletions() {
    const assigneeIds = assignees.map(el => el.eventUserId).filter(el => Number.isInteger(el));
    const eventUserIds = eventUsers.map(el => el.eventUserId);

    return eventUserIds.filter(el => !assigneeIds.includes(el));
  }

  // Extract event data when it's loaded from the backend
  function onLoadEvent(res) {
    if (!res.data.event) {return; }
    setEvent(res.data.event);
    setEventUsers(res.data.eventUsers.filter(el => el.isEffectiveEventUser === false));
  }

  /* --Effects--------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  useEffect(() => {
    if (!isValid) {
      setHideActions(['Save']);
    }
    else {
      setHideActions([]);
    }
  }, [isValid]);

  // Open editior in create mode
  useEffect(() => {
    if (createMode === true) {
      setIsDisabled(false);
    }
  }, [createMode]);

  // Configure create mode by disabling inputs
  useEffect(() => {
    if (createMode) {
      setIsDisabled(false);
    }
  }, []);

  // Load default values from backend into inputs
  useEffect(() => {
    if (event) {
      setTitle(event.name);
      setStartTime(stringToDayjs(event.eventDate, event.startTime));
      setEndTime(stringToDayjs(event.eventDate, event.endTime));
      setDate(stringToDayjs(event.eventDate, event.startTime));
      setEventType(event.eventCategoryName ? event.eventCategoryName : '');
      if (event.isFinal) {
        setHideActions(['Edit', 'Plan', 'Save', 'Delete']);
      }
    }
  }, [event]);

  // Makes it so dialog is empty when opening after closing another one
  useEffect(() => {
    if (!isOpen) {
      setEvent();
      setEventUsers([]);
    }
  }, [isOpen]);

  /* --Server Communication-------------------------------------------------- */
  /* ------------------------------------------------------------------------ */
  // Load Event from event id
  useEffect(() => {
    if (eventId && isOpen) {
      api.getEvent(eventId, onLoadEvent);
      api.getEventPicture(eventId, (res) => setImage(res.data.image));
    }
  }, [isOpen, eventId, updateCounter]);


  // Event Creation in Backend
  useEffect(() => {
    async function createEvent() {
      //Create event
      const reqEventType = (eventType !== '') ? eventType : null;
      const event = (await api.createEvent(title, date, startTime, endTime, reqEventType, (res) => {
        setEvent(res.data)
      })).data;

      if (image) {
        await api.addEventPicture(event.id, image);
        setHasNewImage(false);
      }

      //Create event users (placeholders)
      let body = [];
      for (let el of assignees) {
        if (el.shouldUpdate) {
          body.push(el.getRequest());
        }
      }

      if (body.length > 0) {
        await api.addAssignees(event.id, body);
      }

      //Update
      await onUpdate();
      close();
      setIsCreating(false);
    }
    if (isCreating) {
      createEvent();
    }
  }, [isCreating])

  //Save edited Event
  useEffect(() => {
    async function saveEvent() {
      const responses = [];

      const reqEventType = (eventType !== '') ? eventType : null;
      try {
        responses.push(await api.updateEvent(event.id, title, date, startTime, endTime, reqEventType, (res) => { setEvent(res.data) }))
      } catch (err) {
        if (err.response?.status === 409) {
          setUpdateCounter(updateCounter + 1);
          setNotify({ text: eventConflictMessage(err.response?.data, translationList), severity: 'error' });
          setShowNotify(true);
          return;
        }
      }

      const toDelete = findDeletions();
      if (toDelete.length > 0) {
        responses.push(await api.deleteAssignees(toDelete));
      }

      //Update and Create Assignees
      let body = [];
      for (let el of assignees) {
        if (el.shouldUpdate) {
          body.push(el.getRequest());
        }
      }

      if (body.length > 0) {
        try {
          responses.push(await api.addAssignees(event.id, body));
        } catch (err) {
          responses.push(err.response);
          if (err.response?.status === 409) {
            setUpdateCounter(updateCounter + 1);
            setNotify({ text: eventConflictMessage(err.response?.data, translationList), severity: 'error' });
            setShowNotify(true);
            return;
          }
        }
      }

      if (hasNewImage) {
        await api.addEventPicture(event.id, image);
        setHasNewImage(false);
      }

      console.log(responses)
      if (responses.every((el) => el.status === 200)) {
        setNotify({ text: translationList.savedChangesSuccessfully, severity: 'success' });
      } else {
        setNotify({ text: translationList.somethingWrong, severity: 'error' });
      }
      setShowNotify(true);

      api.getEvent(event.id, onLoadEvent);
      setIsSaving(false);
      onUpdate();
    }
    if (isSaving) {
      saveEvent();
    }
  }, [isSaving])

  // Load eventUsers from backend as Assignees
  useEffect(() => {
    const newAssignees = [];
    async function fetchUsers() {
      let tmp;
      for await (let el of eventUsers) {
        tmp = eventUserToAssignee(el);
        if (el.userId) {
          const res = await api.getUser(el.userId);
          if (res?.status === 200 && res?.data) {
            tmp.user = res.data;
          }
        }
        newAssignees.push(tmp);
      }
      setAssignees(newAssignees);
    }
    if (eventUsers) {
      fetchUsers()
    }
  }, [eventUsers])

  /* --Render---------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */
  if (assignees === null) return <CircularProgress />

  return (
    <EventContext.Provider
      value={{
        event,
        eventUsers,
        assignees,
        setAssignees,
        eventState: {
          date: date,
          startTime: startTime,
          endTime: endTime,
        },
      }}
    >
      <Fragment>

        {/*--Dialogs-----------------------------------------------------------*/}
        <ConfirmationDialog
          isOpen={showDelete}
          text={`${translationList.deleteEventConfirmation}: ${event?.name}?`}
          onClose={() => setShowDelete(false)}
          onConfirm={() => {
            api.deleteEvent(event.id, onUpdate);
            close();
          }
          }
        />

        <ConfirmationDialog
          isOpen={showUnsaved}
          text={translationList.unsavedChangesConfirmation}
          onClose={() => setShowUnsaved(false)}
          onConfirm={() => {
            close();
          }
          }
        />

        {/*--Form--------------------------------------------------------------*/}
        <StaticWindow
          isOpen={isOpen}
          onClose={handleClose}
          slotProps={backdropProp}
          sx={{
            width: '90%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              '@media (max-width:600px)': {
                marginTop: '-20px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 1,
              }}
            >
            </Box>
            <Notification
              text={notify.text}
              severity={notify.severity}
              isOpen={showNotify}
              onClose={() => setShowNotify(false)}
            />
            <TextInput
              disabled={isDisabled}
              label={translationList.title}
              variant='standard'
              fullWidth
              value={title}
              onChange={e => setTitle(e.target.value)}
              onValidChange={onValidChange}
            />

            <Box
              sx={{
                display: 'flex',
                gap: 1,
                '@media (max-width:600px)': {
                  paddingTop: 1
                }
              }}
            >
              <ImageUpload
                disabled={isDisabled}
                onUpload={handleImageUpload}
                defaultImage={image}
              />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  '@media (max-width:600px)': {
                    gap: 1.5,
                  }
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    timezone="Europe/Zurich"
                    format={'DD.MM.YYYY'}
                    disabled={isDisabled}
                    label={translationList.date}
                    value={date}
                    onChange={(newDate) => setDate(newDate)}
                    sx={{ input: {
                        '@media (max-width:600px)': {
                          fontSize: '0.7rem',
                          boxSizing: 'border-box',
                          paddingLeft: '12px',
                          paddingRight: '4px',
                        }}
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                    }}
                  >
                    <TimePicker
                      disabled={isDisabled}
                      label={translationList.begin}
                      value={startTime}
                      timezone="Europe/Zurich"
                      ampm={false}
                      onChange={(newTime) => setStartTime(newTime)}
                      sx={{ input: {
                          '@media (max-width:600px)': {
                            fontSize: '0.7rem',
                            boxSizing: 'border-box',
                            paddingLeft: '4px',
                            paddingRight: '4px',
                          }}
                      }}
                    />
                    <TimePicker
                      disabled={isDisabled}
                      label={translationList.end}
                      timezone="Europe/Zurich"
                      ampm={false}
                      value={endTime}
                      onChange={(newTime) => setEndTime(newTime)}
                      sx={{ input: {
                          '@media (max-width:600px)': {
                            fontSize: '0.7rem',
                            boxSizing: 'border-box',
                            paddingLeft: '4px',
                            paddingRight: '4px',
                          }}
                      }}
                    />
                  </Box>

                  <FormControl fullWidth>
                    <InputLabel
                        sx={{
                          '@media (max-width:600px)': {
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            textAlign: 'center',
                            left: -10,
                            ...(eventType === '' && {
                              top: '50%',
                              transform: 'translateY(-50%)',
                              position: 'absolute',
                              left: 6
                            }),
                          },
                        }}
                        shrink={(eventType !== '')}
                    >
                      {translationList.eventType}
                    </InputLabel>
                    <Select
                        sx={{
                          '@media (max-width:600px)': {
                            height: 30,
                            width: '100%',
                            '& .MuiSelect-select': {
                              display: 'flex',
                              alignItems: 'center',
                              padding: '20px 14px',
                              overflow: 'hidden',
                            },
                            '& .MuiInputBase-input': {
                              padding: '0',
                              paddingLeft: 1,
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              fontSize: 12,
                            },
                          },
                        }}
                        disabled={isDisabled}
                        value={eventType}
                        onChange={(e) => setEventType(e.target.value)}
                    >
                      {eventCategories.map((el) => (
                          <MenuItem key={el.id} value={el.name}>
                            {translationList[el.name]}
                          </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </LocalizationProvider>
              </Box>
            </Box>

            {/*--Assignees-----------------------------------------------------*/}
            <AssigneePicker
              isDisabled={isDisabled}
              isPlaceholderMode={(isPlanMode || createMode)}
            />
            <Permission level={5}>
              {!createMode ? (
                  !event?.isFinal &&
                  <ActionDial
                      hideActions={hideActions}
                      onAction={{
                        'Info': () => { setIsDisabled(true) },
                        'Edit': () => { setIsDisabled(false); setIsPlanMode(false) },
                        'Plan': () => { setIsDisabled(false); setIsPlanMode(true) },
                        'Save': () => { setIsSaving(true) },
                        'Delete': () => { setShowDelete(true) },
                      }}
                  />
              ) : (
                  <DialogActions>
                    {(isCreating) ? <CircularProgress /> :
                        <Button
                            disabled={!isValid}
                            variant='contained'
                            startIcon={<SaveIcon />}
                            onClick={() => {
                              handleCreate();
                            }}
                        >
                          {translationList.save}
                        </Button>
                    }
                  </DialogActions>
              )}
            </Permission>
          </Box>
        </StaticWindow>
      </Fragment>
    </EventContext.Provider>
  );
}

export { EventEditor }
export default EventEditor;