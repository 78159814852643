import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Paper,
  Divider,
  ListItem,
  Typography,
  IconButton,
  ButtonBase,
} from '@mui/material';
import { useTheme } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import ClearIcon from '@mui/icons-material/Clear';
import UserAvatar from '../../features/users/UserAvatar';
import { SecondaryWindow } from '../../components/ui/windows';
import AssigneeEdit from './AssigneEdit';
import { EventContext } from '../../context/EventContext';
import NightIcon from '@mui/icons-material/Nightlight';
import RegisterIcon from '@mui/icons-material/PointOfSale';

/* Card representing an Assignee */
function AssigneeItem({ assignee, onEdit, isDisabled, isFinal=false }) {
  const { event, eventState, assignees, setAssignees } = useContext(EventContext);
  const { container: {item}, palette } = useTheme();
  const [isEditOpen, setIsEditOpen] = useState(false);

  function handleEdit() {
    setIsEditOpen(true)
  }

  return(
    <ListItem
      sx={{
        paddingX: 0,
        paddingY: '3px',
      }}
    >
      <SecondaryWindow
        isOpen={isEditOpen}
        onClose={ () => setIsEditOpen(false) }
        hideBackdrop={true}
        variant='secondary'
      >
        <AssigneeEdit
          assignee={assignee}
          onClose={() => setIsEditOpen(false)}
        />
      </SecondaryWindow>

      <Paper
        {...item.paper}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '70px',
          borderRadius: '80px',
          paddingX: 1,
          paddingY: 2,
          width: '100%',
          gap: 1,
        }}
        >
        <UserAvatar
          user={assignee.user}
          sx={{
            width: '56px',
            height: '56px',
          }}
        />
        <Box
          sx={{
            bgcolor: (assignee.responsibility && assignee.responsibility !== 'none') ? palette.grey[600] : null,
            position: 'relative',
            borderRadius: '100%',
            width: '42px',
            height: '32px',
            left: '-20px',
            marginRight: '-26px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
        {assignee.responsibility === 'evening' &&
          <NightIcon
            sx={{
              width: '80%',
              height: '80%',
            }}
          />
        }
        {assignee.responsibility === 'register' &&
          <RegisterIcon
            sx={{
              width: '80%',
              height: '80%',
            }}
          />
        }
        </Box>

        <ButtonBase
          disabled={isDisabled}
          onClick={handleEdit}
          sx={{
            width: '100%',
            height: '70px',
          }}
        >

          <Box
            sx={{
              width: '100%',
              padding: 1,
            }}
          >
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              { (assignee.user !== null) &&
                <Typography variant='body2' style={{ textAlign: 'left'}}>
                    {`${assignee.user.firstName} ${assignee.user.lastName}`}
                </Typography>
              }
                <Divider />
                <Typography variant='body2' style={{ textAlign: 'left'}}>
                    {`${assignee.startTime} - ${assignee.endTime}`}
                </Typography>
            </Box>
          </Box>

        </ButtonBase>

        <Box>
          { assignee.isFinal && (<LockIcon fontSize='large' color='disabled'/>)}
        </Box>
        <Box>
          { !isDisabled && (
            <IconButton
              onClick={ () => setAssignees( assignees.filter( el => el.eventUserId !== assignee.eventUserId ))}
              size='small'
              sx={{gridColumn: '12'}}
              color='secondary'
            >
              <ClearIcon />
            </IconButton>
          )}
        </Box>
      </Paper>
    </ListItem>
  )
}


          //<MobileTimePicker
          //  label='Begin'
          //  value={startTime}
          //  onChange={ (newTime) => setStartTime(newTime) }
          //  sx={{gridColumn: '4'}}
          ///>
          //<MobileTimePicker
          //  label='End'
          //  value={endTime}
          //  onChange={ (newTime) => setEndTime(newTime) }
          //  sx={{gridColumn: '5'}}
          ///>

export default AssigneeItem;