import React, { useEffect, useState } from 'react';
import {
  Box,
  Avatar,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material';
import { getProfilePicture } from '../../services/mediaAPI';
import { set } from 'react-hook-form';

function UserAvatar({ user, color = 'primary', sx = {} }) {
  const { palette } = useTheme();
  const [image, setImage] = useState();

  useEffect(() => {
    if (user) {
      (async () => {
        const res = await getProfilePicture(user.id);
        if (res?.data?.image === undefined) { return; }
        setImage(res.data.image);
      })();
      //getProfilePicture(user.id, res => setImage(res.data.image));
    }
  }, [user]);


  if (user) {
    return (
      <Avatar
        src={image}
        sx={{
          bgcolor: palette[color].dark,
          ...sx,
        }}
      >
        {user.firstName && user.lastName && (
          <>{user.firstName[0] + user.lastName[0]}</>
        )}
      </Avatar>
    );
  } else {
    return (
      <Avatar
        sx={{
          bgcolor: palette.grey[700],
          ...sx,
        }}
      >
      </Avatar>
    )
  }
}

export { UserAvatar }
export default UserAvatar;