import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Typography,
} from '@mui/material';


import PersonalDataView from './PersonalDataView';

function PersonalDataLogic({ defaultValues, onSubmit, image, setImage, userId, disabled }) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  async function handleSubmit(data) {
    await onSubmit(data, {userFunctionNames: data.userFunctions.map( el => el.name)});
  };

  return (
    <PersonalDataView
      form={form}
      onSubmit={handleSubmit}
      image={image}
      setImage={setImage}
      userId={userId}
      disabled={disabled}
    />
  );
}

export default PersonalDataLogic;