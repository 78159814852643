import React, { useState, useEffect, Fragment, useContext, useCallback } from 'react';
import {
  Box,
  Tab,
  Tabs,
  CircularProgress,
  Typography,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

import AssigneePicker from '../assignee/AssigneePicker';
import { backdropProp } from '../../styles/styles';
import { StaticWindow } from '../../components/ui/windows';
import { ActionDial } from '../../components/ui/buttons';
import { Notification } from '../../components/ui/Notification';
import ConfirmationDialog from '../../components/boilerplate/ConfirmationDialog';
import * as api from '../../services';
import { loadTicket } from './ticket';

import { EventContext } from '../../context/EventContext';
import { DefinitionsContext } from '../../context/DefinitionsContext';
import { eventUserToAssignee } from '../assignee/AssigneeObject';
import TicketSales from './TicketSales';
import { TabPanel } from '../../components/ui/containers';
import { CurrentUserContext } from '../../context/CurrentUserContext';
import { hasPermission } from '../../components/wrapper/Permission';
import { eventConflictMessage } from '../../utils/eventConflict';
import { useAreInputsValid } from '../../components/inputs';

function EventEvalEditor({ isOpen, eventId = null, onClose, onUpdate, children }) {
  const { currentUser } = useContext(CurrentUserContext);
  const { translationList } = useContext(DefinitionsContext);
  const [isValid, onValidChange] = useAreInputsValid();

  // Data
  const [event, setEvent] = useState(null);
  const [eventUsers, setEventUsers] = useState(null);
  const [notify, setNotify] = useState({ text: '', severity: 'info' });
  const [assignees, setAssignees] = useState(null); //local state of eventUsers
  const [tickets, setTickets] = useState([]);

  // Dialogs
  const [showNotify, setShowNotify] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showUnsaved, setShowUnsaved] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  // State
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isFinal, setIsFinal] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [isPermitted, setIsPermitted] = useState(false);
  const [hasPlaceholder, setHasPlaceholder] = useState(false);
  const [hideActions, setHideActions] = useState(['Plan', 'Delete']);
  const [updateCounter, setUpdateCounter] = useState(0);

  /* --Functions------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */
  // Checks if user should be warned about unsaved changes
  function handleClose() {
    const isMutated = !(assignees.map(el => el.status).every(val => val === 'original'));
    const isDeletion = (findDeletions().length !== 0);
    if ((isMutated || isDeletion) && !isFinal) {
      setShowUnsaved(true);
    } else {
      close();
    }
  }

  // Resets states and closes dialog
  function close() {
    resetState();
    onClose();
  }

  // Reset all states
  function resetState() {
    setEvent(null);
    setEventUsers(null);
    setAssignees(null);
    setNotify({ text: '', severity: 'info' });

    setShowNotify(false);
    setShowDelete(false);
    setShowUnsaved(false);

    setIsDisabled(true);
    setIsSaving(false);
    setIsLocked(false);
  }

  // Check if any delete eventUser calls are needed when saving an edited event
  function findDeletions() {
    const assigneeIds = assignees.map(el => el.eventUserId).filter(el => Number.isInteger(el));
    const eventUserIds = eventUsers.map(el => el.eventUserId);

    return eventUserIds.filter(el => !assigneeIds.includes(el));
  }

  const isResponsibleFor = useCallback((userId, responsibility) => {
    const users = eventUsers.filter(el => el.responsibilityTypeName === responsibility);
    if (users.length === 0) {
      return false;
    }
    return users.filter(el => el.userId === userId).length > 0;
  }, [eventUsers]);

  const checkIsPermitted = useCallback((userId) => {
    const isRegisterResponsible = isResponsibleFor(userId, 'register');
    const isEveningResponsible = isResponsibleFor(userId, 'evening');
    const isBoss = hasPermission(5, currentUser);
    return isRegisterResponsible || isEveningResponsible || isBoss;
  }, [currentUser, isResponsibleFor]);

  /* --Effects--------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */
  useEffect(() => {
    if (!assignees) { return; }
    setHasPlaceholder(assignees.some(el => !el?.user));
  }, [assignees]);

  useEffect(() => {
  }, [hideActions]);

  useEffect(() => {
    if (!isValid) {
      setHideActions(['Plan', 'Delete', 'Save']);
      setNotify({ text: translationList.invalidEntriesTicketTab, severity: 'warning' });
      setShowNotify(true);
    }
    else if (hasPlaceholder) {
      setHideActions(['Plan', 'Delete', 'Save']);
      setNotify({ text: translationList.submitAssigneesPlaceholder, severity: 'warning' });
      setShowNotify(true);
    }
    else {
      setHideActions(['Plan', 'Delete']);
    }
  }, [hasPlaceholder, isValid]);

  useEffect(() => {
    if (currentUser && eventUsers) {
      setIsPermitted(checkIsPermitted(currentUser.id));
    }
  }, [currentUser, eventUsers, checkIsPermitted]);

  // Load default values from backend into inputs
  useEffect(() => {
    if (event) {
      setEvent(event);
    }
  }, [event]);

  // Makes it so dialog is empty when opening after closing another one
  useEffect(() => {
    if (!isOpen) {
      setEvent();
      setEventUsers([]);
    }
  }, [isOpen]);

  /* --Server Communication-------------------------------------------------- */
  /* ------------------------------------------------------------------------ */
  // Load Event from event id
  useEffect(() => {
    if (eventId && isOpen) {
      (async () => {
        const res = await api.getEvent(eventId);
        setEvent(res.event);
        const hasEffective = res.eventUsers.map(el => el.isEffectiveEventUser).includes(true);
        setEventUsers(res.eventUsers.filter(el => el.isEffectiveEventUser === hasEffective));
        setIsFinal(res.event.isFinal);
      })();
    }
  }, [eventId, isOpen, updateCounter]);

  //Save edited Event
  useEffect(() => {
    async function saveEvent() {
      setIsLocked(true);
      const responses = [];

      //const reqEventType = (eventType !== '') ? eventType : null;
      //responses.push( await api.updateEvent(event.id, title, date, startTime, endTime, reqEventType, (res) => {
      //  setEvent(res.data)}) );

      const toDelete = findDeletions();
      if (toDelete.length > 0) {
        responses.push(await api.deleteAssignees(toDelete));
      }

      //Update and Create Assignees
      let body = [];
      for (let el of assignees) {
        el.status = 'modified';
        body.push(el.getRequest());
      }

      //Saving effective event users
      if (body.length > 0) {
        try {
          responses.push(await api.addAssignees(event.id, body, true));
        } catch (err) {
          if (err.response?.status === 409) {
            setUpdateCounter(updateCounter + 1);
            setIsLocked(false);
            setNotify({ text: eventConflictMessage(err.response?.data, translationList), severity: 'error' });
            setShowNotify(true);
          }
        }
      }

      //Saving ticket sales
      for (let el of tickets) {
        if (el.status === 'new') {
          api.addTicket(el.json);
        }
        else if (el.status === 'modified') {
          api.updateTicket(el.id, el.json);
        }
      }

      //Generating Timerequests
      responses.push(await api.generateAllTimeRequests(event.id));
      setIsFinal(true);

      if (responses.every((el) => el.status === 200)) {
        setNotify({ text: translationList.savedChangesSuccessfully, severity: 'success' });
      } else {
        setNotify({ text: translationList.somethingWrong, severity: 'error' });
      }
      setShowNotify(true);

      //api.getEvent(event.id, onLoadEvent);
      setIsSaving(false);
      onUpdate();
    }
    if (isSaving) {
      if (assignees?.length === 0 || !assignees) {
        setNotify({ text: translationList.saveWithoutAssigningSomeone, severity: 'warning' });
        setShowNotify(true);
        setIsSaving(false);
        return;
      }
      saveEvent();
    }
  }, [isSaving])

  // Load tickets from backend
  useEffect(() => {
    async function fetchTicket() {
      let res = await api.getTicket(event.id);
      if (!res.data) { return; }
      let loadedTickets = res.data.map(el => loadTicket(el));
      //Double check to avoid double tickets
      if (loadedTickets.length === 0) {
        res = await api.getTicket(event.id);
        loadedTickets = res.data.map(el => loadTicket(el));
      }
      if (loadedTickets.length === 0) {
        await api.addTicket({
          'eventId': event.id,
          'ticketTypeId': 1,
          'quantity': 0,
          'price': 0,
        });
        await api.addTicket({
          'eventId': event.id,
          'ticketTypeId': 2,
          'quantity': 0,
          'price': 0,
        });
        res = await api.getTicket(event.id);
        if (!res.data) { return; }
        loadedTickets = res.data.map(el => loadTicket(el));
      }
      setTickets(loadedTickets);
    }
    if (event) {
      fetchTicket();
    }
  }, [event]);


  // Load eventUsers from backend as Assignees
  useEffect(() => {
    const newAssignees = [];
    async function fetchUsers() {
      let tmp;
      for await (let el of eventUsers) {
        tmp = eventUserToAssignee(el);
        if (el.userId) {
          tmp.user = (await api.getUser(el.userId)).data;
        }
        newAssignees.push(tmp);
      }
      setAssignees(newAssignees);
    }
    if (eventUsers) {
      fetchUsers()
    }
  }, [eventUsers])

  /* --Render---------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */
  if (assignees === null) return <CircularProgress />

  return (
    <EventContext.Provider
      value={{
        event,
        eventUsers,
        assignees,
        setAssignees,
        //eventState: {
        //  date: date,
        //  startTime: startTime,
        //  endTime: endTime,
        //},
      }}
    >
      <Fragment>
        {/*--Dialogs-----------------------------------------------------------*/}
        <ConfirmationDialog
          isOpen={showDelete}
          title={translationList.areYouSure}
          text={`${translationList.wantDeleteEvent} '${event?.name}'?`}
          onClose={() => setShowDelete(false)}
          onConfirm={() => {
            api.deleteEvent(event.id, onUpdate);
            close();
          }
          }
        />

        <ConfirmationDialog
          isOpen={showUnsaved}
          text={translationList.unsavedChangesConfirmation}
          onClose={() => setShowUnsaved(false)}
          onConfirm={() => {
            close();
          }
          }
        />
        <ConfirmationDialog
          isOpen={showSubmit}
          text={translationList.sureToSubmitEventEval}
          onClose={() => setShowSubmit(false)}
          onConfirm={() => setIsSaving(true)}
        />
        {/*--Form--------------------------------------------------------------*/}
        <StaticWindow
          isOpen={isOpen}
          onClose={handleClose}
          slotProps={backdropProp}
          sx={{ width: '90%' }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              width: '100%',
            }}
          >
            <Notification
              text={notify.text}
              severity={notify.severity}
              isOpen={showNotify}
              onClose={() => setShowNotify(false)}
            />

            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}>
              <Typography variant='h5'>{event?.name}</Typography>
              {isFinal && <LockIcon color={'disabled'} />}
            </Box>

            {hasPermission(5, currentUser) && (
            <Tabs
              value={activeTab}
              onChange={(e, val) => setActiveTab(val)}
            >
              <Tab label={translationList.hours} />
              <Tab label={translationList.tickets} />
            </Tabs>
            )}

            <TabPanel value={activeTab} index={0}>
              <AssigneePicker
                isDisabled={isDisabled || isLocked}
                hideFab={true}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <TicketSales
                tickets={tickets}
                disabled={isFinal || isDisabled || isLocked}
                setTickets={setTickets}
                onValidChange={onValidChange}
              />
            </TabPanel>
            {(isPermitted && !isFinal && !isLocked) && (
              <ActionDial
                hideActions={hideActions}
                onAction={{
                  'Info': () => setIsDisabled(true),
                  'Edit': () => setIsDisabled(false),
                  'Save': () => setShowSubmit(true),
                }}
              />
            )}
          </Box>
        </StaticWindow>
      </Fragment>
    </EventContext.Provider>
  );
}

export { EventEvalEditor }
export default EventEvalEditor;