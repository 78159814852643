import React, { useContext, useState } from 'react';
import {
  Box,
  Paper,
  Button,
  Divider,
} from '@mui/material';

import {
  FormTextInput,
  FormNumberInput,
  FormRadioInput,
  FormPhoneInput,
} from '../../components/form';
import { ImageUpload } from '../../components/inputs/ImageUpload';
import { FormDateInput } from '../../components/form/FormDateInput';

import { DefinitionsContext } from "../../context/DefinitionsContext";
import { useAreInputsValid } from '../../components/inputs/useAreInputsValid';
import { CurrentUserContext } from '../../context/CurrentUserContext';

function PersonalDataView({ form, onSubmit, image, setImage, userId, disabled }) {
  const { currentUser } = useContext(CurrentUserContext);
  const { translationList } = useContext(DefinitionsContext);
  const { formState, control, handleSubmit } = form;
  const { errors, isSubmitting } = formState;
  const [isValid, onValideChange] = useAreInputsValid();
  const divider = <Divider sx={{ marginTop: '10px', marginBottom: '15px' }} />

  return (
    <Box
      sx={{
        name: 'PersonalDataView',
        display: 'flex',
        flexDirection: 'column',
        width: '97%',
        paddingLeft: 0.5,
        gap: 1,
        input: {
          '@media (max-width:600px)': {
            fontSize: '0.6rem',
          }}
      }}
    >

      <Box sx={{ display: 'flex', flex: 1, gap: 1,}} >
        <ImageUpload onUpload={setImage} defaultImage={image} disabled={disabled} />
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 1, }} >
          <FormTextInput onValideChange={onValideChange} name='firstName' isRequired={true} label={translationList.firstname} control={control} disabled={disabled} />
          <FormTextInput onValideChange={onValideChange} name='lastName' isRequired label={translationList.lastname} control={control} disabled={disabled} />
        </Box>
      </Box>

      {divider}

      <Box sx={{ display: 'flex', flex: 1, gap: 1,  fontSize: 10}} >
        <FormTextInput onValideChange={onValideChange}
                       name='street' label={translationList.street}
                       control={control}
                       sx={{ flex: 6,
                       }}
                       disabled={disabled} />
        <FormTextInput onValideChange={onValideChange}
                       max={4} name='zip'
                       digitsOnly
                       label={translationList.zip}
                       control={control} sx={{ flex: 2 }}
                       disabled={disabled}
                      sx={{
                          input: {
                            '@media (max-width:600px)': {
                                width: 25,
                           }}
                     }}
        />
        <FormTextInput onValideChange={onValideChange} name='location' label={translationList.city} control={control} sx={{ flex: 4 }} disabled={disabled} />
      </Box>

      <FormDateInput onValideChange={onValideChange} name='birthdate' label={translationList.birthdate} control={control} disabled={disabled} languageId={currentUser.languageId}/>
      <FormTextInput onValideChange={onValideChange} name='ahvNumber' label={translationList.ahvNumber} control={control} disabled={disabled} />
      <FormNumberInput onValideChange={onValideChange} name='numberOfKids' label={translationList.numberOfKids} min={0} max={100} control={control} disabled={disabled} />
      <FormTextInput onValideChange={onValideChange} name='email' isRequired label={translationList.email} control={control} type='email' disabled={disabled} />
      <FormPhoneInput name='phoneNumber' label={translationList.phoneNumber} control={control} type='tel' disabled={disabled} />
      <FormPhoneInput name='mobileNumber' label={translationList.mobileNumber} control={control} type='tel' disabled={disabled} />
      <FormRadioInput name='correspondanceType' label={translationList.correspondanceType} control={control}
        preselect={'email'}
        entries={[
          { value: 'email', label: translationList.email },
          { value: 'post', label: translationList.post }
        ]}
        disabled={disabled}
      />
      {divider}
      <FormTextInput onValideChange={onValideChange} name='nationality' label={translationList.nationality} control={control} disabled={disabled} />
      <FormTextInput onValideChange={onValideChange} name='workingPermit' label={translationList.workingPermit} control={control} disabled={disabled} />
      <FormTextInput onValideChange={onValideChange} name='taxCanton' label={translationList.taxCanton} control={control} disabled={disabled} />
      <FormTextInput onValideChange={onValideChange} name='otherEmployer' label={translationList.otherEmployer} control={control} disabled={disabled} />


      {divider}

      {!disabled &&
        <Button
            sx={{
              marginBottom: 5,
            }}
          disabled={isSubmitting || !isValid}
          onClick={handleSubmit(onSubmit)}
          variant='contained'
        >
          {translationList.submit}
        </Button>
      }
    </Box>
  );
}

export default PersonalDataView;