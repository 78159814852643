import axios from 'axios';
import api from './api';

async function login(username, password) {
  //const ip = await axios.get('https://api64.ipify.org?format=json')
  try {
    const res = await api.post(
      'auth/login',
      {
        username: username,
        password: password,
      },
      {
        withCredentials: true,
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
}

async function logout() {
  const res = await api.get(
    'auth/logout',
  );
  return res;
};

async function changePassword(currentPassword, newPassword, confirmationPassword) {
  try {
    const res = await api.post(
      `auth/password`,
      {
        currentPassword: currentPassword,
        newPassword: newPassword,
        confirmationPassword: confirmationPassword,
      },
    )
    return res;
  } catch (error) {
    throw error;
  }
}


async function refreshToken() {
  const res = await api.post(
    'auth/refresh-token',
    {},
    {
      withCredentials: true,
    },
  );
  return res;
}

async function sendResetPasswordMail(username) {
  const res = await api.post(
    'auth/sendResetPasswordMail',
    {},
    {
      params: { username: username, },
      withCredentials: false,
    },
  );
  return res;
}

async function checkResetToken(token) {
  const res = await api.get(
    'auth/checkResetToken',
    {
      params: { token: token, },
      withCredentials: false,
    },
  );
  return res;
}

async function resetPassword(newPassword, confirmPassword, token) {
  try {
    const res = await api.post(
      'auth/resetPassword',
      {
        newPassword: newPassword,
        confirmationPassword: confirmPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: false,
      }
    );
    return res?.data;
  } catch (error) {
    console.error('Error resetting password:', error.response?.data?.message);
    throw error;
  }
}

export { login, logout, refreshToken, changePassword, sendResetPasswordMail, checkResetToken, resetPassword };