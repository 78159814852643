import { api, defaultCallback } from './api';

async function addUser(firstName, lastName, email, cb = defaultCallback) {
  try {
    const res = await api.post(
      `user`,
      { firstName: firstName, lastName: lastName, email: email }
    )

    cb(res);
    return res;
  } catch (error) {
    throw error;
  }
}

async function deleteUser(userId, cb = defaultCallback) {
  const res = await api.delete(`user/${userId}`)

  cb(res);
  return res;
}

async function listUsers(cb = defaultCallback) {
  const res = await api.get('user/')
  cb(res?.data);
  return res?.data;
}

async function getUser(userId, cb = defaultCallback) {
  const res = await api.get(`user/${userId}`)
  cb(res);

  return res;
}

async function updateUser(userId, data, cb = defaultCallback) {
  const res = await api.put(`user/${userId}`, data);
  cb(res?.data);
  return res;
}

async function updateUserFunctions(userId, functionNames, cb = defaultCallback) {
  const res = await api.put(`user/${userId}/updateUserFunctions`, functionNames);
  cb(res?.data);
  return res?.data;
}

async function listUserFunctions(cb = defaultCallback) {
  const res = api.get(`userFunctions`)

  cb(res);
  return res;
}

async function getUserBankAccount(userId, cb = defaultCallback) {
  const res = api.get(
    `user/${userId}/getUserBankAccount`
  );

  cb(res);
  return res;
}

async function addUserBankAccount(userId, data, cb = defaultCallback) {
  const res = api.post(
    `user/${userId}/addBankAccount`,
    data
  );

  cb(res);
  return res;
}

export {
  listUsers,
  addUser,
  deleteUser,
  getUser,
  updateUser,
  updateUserFunctions,
  listUserFunctions,
  getUserBankAccount,
  addUserBankAccount,
}