import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Button,
} from '@mui/material';
import dayjs from 'dayjs';
import EventIcon from '@mui/icons-material/TheaterComedy';
import UserIcon from '@mui/icons-material/Person';

import { ApprovalItemActive } from './ApprovalItem';
import { calcDuration } from '../../utils/dateUtils';

function TimeRequestList({ times, onDetail }) {
  return (
    <List>
      {times.map(el => <TimeRequestItem time={el} key={el.id} onDetail={onDetail} />)}
    </List>
  );
}

function TimeRequestItem({ time, onDetail }) {
  return (
    <ListItem >
      <ListItemButton onClick={ e => onDetail(time) }>
        <ApprovalItemActive approval={time.approval} />
        <ListItemText
          primary={`${calcDuration(time.date, time.startTime, time.endTime)}`}
          secondary={`${dayjs(time.date).format('D.M.')} ${time.startTime}-${time.endTime}`}
        />
        {time.requestType.name === 'event' ? <EventIcon fontSize='large' /> : <UserIcon fontSize='large' />}
      </ListItemButton>
    </ListItem>
  )
}

export { TimeRequestList }
export default TimeRequestList;