import { useState, useEffect } from 'react';
import { TextField, Box } from '@mui/material';
import { set } from 'react-hook-form';

function NumberInput({ label, value, onChange, onValidChange, min = 0, isRequired, disabled = false, id=undefined, sx = {}, ...props }) {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  useEffect(() => {
    if (value < min) {
      setError(true);
      setHelperText(`Value should be at least ${min}`);
      onValidChange && onValidChange(id ? id : label, false);
    } 
    else if (isRequired && isNaN(value)) {
      setError(true);
      setHelperText('This field is required');
      onValidChange && onValidChange(id ? id : label, false);
    }
    else {
      setError(false);
      onValidChange && onValidChange(id ? id: label, true);
    }
  }, [value, min, label, onValidChange, id, isRequired]);

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <TextField
        label={label}
        value={value}
        disabled={disabled}
        type="number"
        onChange={(e) => { if (onChange) onChange(parseInt(e.target.value)); }}
        InputLabelProps={{ shrink: true, }}
        error={error}
        helperText={error ? helperText : null}
        fullWidth
        variant="outlined"
        inputProps={{ min }}
        sx={{
          // Hide the arrows for number input in both Chrome and Firefox
          '& input[type=number]': {
            '-moz-appearance': 'textfield', // Firefox
            '-webkit-appearance': 'none', // Chrome, Safari, Edge
            appearance: 'textfield',
            'margin': 0,
          },
          '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            'margin': 0,
          },
          '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none', // Hide the spin buttons in WebKit browsers (Chrome, Safari, Edge)
            'margin': 0,
          },
        }}
        {...props}
      />
    </Box>
  );
}


export default NumberInput;
export { NumberInput };