import React, {useState, useEffect, useContext} from 'react';
import {
  Box,
  Avatar,
  ListItemAvatar,
  Typography,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import PendingIcon from '@mui/icons-material/Pending';
import ApprovedIcon from '@mui/icons-material/Recommend';
import DeniedIcon from '@mui/icons-material/DoDisturbOn';
import {DefinitionsContext} from "../../context/DefinitionsContext";

import * as api from '../../services/approvalAPI';


function getRepresentation(approval, palette, colorIcons=false) {

  const statusMap = {
    'pending': {
      name: 'Pending',
      icon: <PendingIcon sx={{color: colorIcons && palette.grey[600]}}/>,
      color: palette.grey[600],
    },
    'approved': {
      name: 'Approved',
      icon: <ApprovedIcon sx={{color: colorIcons && palette.success.main}}/>,
      color: palette.success.main,
    },
    'denied': {
      name: 'Denied',
      icon: <DeniedIcon sx={{color: colorIcons && palette.error.main}}/>,
      color: palette.error.main,
    },
  };

  return statusMap[approval.status.name];
}

function ApprovalItem(approval) {
  const { palette } = useTheme();
  const repr = getRepresentation(approval, palette);

  return (
    <ListItemAvatar>
      <Avatar sx={{bgcolor: repr.color}}>
        {repr.obj.icon}
      </Avatar>
    </ListItemAvatar>
  );
}

function ApprovalIcon({approval}) {
  const { palette } = useTheme();
  const repr = getRepresentation(approval, palette, true);

  return (
    repr.icon
  );
}

function ApprovalItemActive({ approval }) {
  const { palette } = useTheme();
  const [repr, setRepr] = useState();

  useEffect( () => {
    if (approval) {
      setRepr(getRepresentation(approval, palette))
    }
  }, [approval]);

  return (
    <ListItemAvatar>
      <Avatar sx={{bgcolor: repr?.color}}>
        {repr?.icon}
      </Avatar>
    </ListItemAvatar>
  );
}

export { ApprovalIcon, ApprovalItem, ApprovalItemActive }
export default ApprovalItem;