import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  List,
  Divider,
  Typography,
  ListItem,
  ListItemButton,
  Button,
  Autocomplete,
  Chip,
  IconButton,
  CircularProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import FunctionPicker from './FunctionPicker';
import '../../styles/styles.css';
import AssigneeSelect from './AssigneeSelect';
import AssigneePlaceholderSelect from './AssigneePlaceholderSelect';
import AssigneeList from './AssigneeList';
import { SecondaryWindow } from '../../components/ui/windows';
import { removeUserById } from '../../utils/userLists';
import { EditableListContainer } from '../../components/ui/containers';
import { EventContext } from '../../context/EventContext';
import { userToAssignee } from './AssigneeObject';
import {DefinitionsContext} from "../../context/DefinitionsContext";
import { filterArchived } from '../../utils/userLists';

/* Assignee Master component.*/
function AssigneePicker({ isDisabled, isPlaceholderMode=false, hideFab=false }) {
  const { event, eventUsers, assignees, setAssignees } = useContext(EventContext);
  const [responsible, setResponsible] = useState();
  const [isAddDialog, setIsAddDialog] = useState(false);

  const { translationList } = useContext(DefinitionsContext);

  function addUser(user) {
    const newAssignee = userToAssignee(user, event);
    setAssignees([...assignees, newAssignee]);
  }

  return (
    <EditableListContainer
      onAdd={ () => setIsAddDialog(true) }
      isDisabled={isDisabled || hideFab}
      icon={isPlaceholderMode ? 'placeholder' : 'add'}
      sx={{
        padding: 1,
        width: '100%',
        maxHeight: '40vh',
        '@media (max-width:600px)': {
          maxHeight: '31vh',
        },
      }}
    >
      {assignees?.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: 20,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 25,
            '@media (max-width:600px)': {
              padding: 5,
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 8,
            },

          }}
        >
          <Typography variant='body1' color='grey' >
            {translationList.noStaffAssigned}
          </Typography>
        </Box>
        )}
      <SecondaryWindow
        isOpen={isAddDialog}
        onClose={ () => setIsAddDialog(false) }
        hideBackdrop={true}
        variant='secondary'
      >
        {isPlaceholderMode ? 
        (
          <AssigneePlaceholderSelect
            onSelect={ selectedUser => {
              addUser(selectedUser);
            }}
          />) : (
        <AssigneeSelect
          onSelect={ selectedUser => {
            addUser(selectedUser);
          }}
        />)
        }
      </SecondaryWindow>

      <AssigneeList
        assignees={assignees}
        isDisabled={ (isDisabled || isPlaceholderMode) }
      />
    </EditableListContainer>
  )
}

export default AssigneePicker;