import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#1b1c1c', // Change this to your desired background color
    },
    primary: {
      light: '#33ab9f',
      main: '#00695f',
      dark: '#00695f',
      contrastText: '#000',
    },
    secondary: {
      light: '#f73378',
      main: '#f50057',
      dark: '#ab003c',
      contrastText: '#fff',
    },
  },
  typography: {
    fontSize: 12, // Default font size
    h1: {
      fontSize: '3rem',
      '@media (max-width:600px)': {
        fontSize: '2rem',
      },
    },
    h2: {
      fontSize: '2.5rem',
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    body1: {
      fontSize: '1rem',
      '@media (max-width:600px)': {
        fontSize: '0.8rem',
      },
    },
    body2: {
      fontSize: '0.875rem',
      '@media (max-width:600px)': {
        fontSize: '0.7rem',
      },
    },
  },
  components: {
    // Add custom styles for specific MUI components if needed
    MuiPaper: {
      styleOverrides: {
        elevation0: {
          boxShadow: 'none',
        },
        elevation1: {
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
  // Custom container settings
  container: {
    dashboard: {
      paper: {
        elevation: 0,
        padding: 6,
        boxShadow: 24,
        borderRadius: '20px',
      },
    },
    dialog: {
      paper: {
        elevation: 3,
        padding: 4,
        boxShadow: 24,
        sx: {
          minWidth: '240px',
          minHeight: '160px',
          borderRadius: '20px',
        },
      },
      paper2: {
        elevation: 6,
        padding: 4,
        boxShadow: 24,
        sx: {
          minWidth: '240px',
          minHeight: '160px',
          borderRadius: '20px',
        },
      },
      slotProps: {
        backdrop: {
          style: {
            backgroundColor: 'rgba(0,0,0,0.01)',
            backdropFilter: 'blur(8px)',
          },
        },
      },
    },
    item: {
      paper: {
        elevation: 3,
      },
    },
    scroll: {
      elevation: 0,
      gap: 1,
      padding: 1,
    },
  },
});

export { darkTheme };
