import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);


function formatDay(dateString) {
  const date = dayjs(dateString, 'YYYY-MM-DD HH:mm')
  return date.format('DD.MM.YYYY')
}

function monthFromDaterange(dateRange) {
  return dayjs(dateRange[0]).format('MMMM-YYYY');
}

function initDateRange() {
  return dateToRange(dayjs());
}

function stringToDayjs(date, time) {
  const formattedDate = dayjs(date).format('YYYY-MM-DD');
  return dayjs(`${formattedDate}T${time}`, 'YYYY-MM-DDTHH:mm')
}

function dayjsToTimeString(date) {
  return date.format('HH:mm')
}

function toDayjsStartEnd(date, startTime, endTime) {
  const test = date.set({
    hour: startTime.hour(),
    minute: startTime.minute(),
  });
  const t0 = date.set({
    hour: startTime.hour(),
    minute: startTime.minute(),
  });
  const t1 = date.set({
    hour: endTime.hour(),
    minute: endTime.minute(),
  });

  return [t0, t1];
}

function dateToRange(date) {
  const startDateStr = `${date.format('YYYY-MM')}-01`;
  const endDateStr   = `${date.format('YYYY-MM')}-${dayjs(startDateStr).daysInMonth()}`;

  return [startDateStr, endDateStr]
}

function calcDuration(date, startTime, endTime) {
  const dt = getDuration(date, startTime, endTime);
  return dt.format('H:mm');
}

function calcTotalRequest(timeRequest) {
  return calcDuration(timeRequest.date, timeRequest.startTime, timeRequest.endTime);
}

function getDuration(date, startTime, endTime) {
  const formattedDate = dayjs(date).format('YYYY-MM-DD');
  const t0 = dayjs(`${formattedDate} ${startTime}`, 'YYYY-MM-DDTHH:mm');
  let t1 = dayjs(`${formattedDate} ${endTime}`, 'YYYY-MM-DDTHH:mm');
  if ( t1.isBefore(t0) ) {
    t1 = t1.add(1, 'day');
  }
  
  return dayjs.duration( t1.diff(t0) );
}

function calcTotalHours(timeRequest) {
  const durations = [];
  for (let el of timeRequest) {
    durations.push( getDuration(el.date, el.startTime, el.endTime) );
  }
  const totalDuration = durations.reduce((acc, cur) => acc.add(cur), dayjs.duration());
  return totalDuration.asHours().toFixed(2);
}

function sumTimeRequests(timeRequests) {

}

export {
  stringToDayjs,
  dayjsToTimeString,
  calcTotalRequest,
  toDayjsStartEnd,
  formatDay,
  initDateRange,
  monthFromDaterange,
  dateToRange,
  calcDuration,
  calcTotalHours
};