import React from 'react';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

function MenuEntry({ title, route, icon, selectedKey, handleClick, isVisible=true }) {
  return(
    <div> 
      { isVisible &&
      <ListItemButton
        selected={selectedKey === route}
        onClick={(event) => handleClick(route, title, event)}
      >
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
      }
    </div>
  )
}

export default MenuEntry;