import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';

import { StaticWindow } from '../../components/ui/windows';
import { MultipleWorkFunctionInput } from '../work-functions/MultipleWorkFunctionInput';
import * as api from '../../services';
import { PermissionSelect } from './PermissionSelect';
import { ConfirmationDialog } from '../../components/boilerplate/ConfirmationDialog';
import { deleteUser } from '../../services/userAPI';
import { set } from 'react-hook-form';

function UserEditor({ userId, isOpen, onClose}) {
  const [user, setUser] = useState();
  const [loadedFun, setLoadedFun] = useState([]);
  const [permission, setPermission] = useState();
  const [modalDelete, setModalDelete] = useState(false);

  async function handleSubmit() {
    const body = {userFunctionNames: loadedFun.map( el => el.name )};
    api.updateUserFunctions(userId, body);
    api.updatePermissions(userId, permission);
    await onClose();
  }

  async function handleDelete() {
    deleteUser(userId);
    setModalDelete(false);
    onClose();
  }

  useEffect( () => {
    if (userId) {
      (async () => {
        //Load user functions
        const res = await api.getUser(userId)
        if (res?.status === 200 && res?.data) {
          setUser(res.data);
          setLoadedFun(res.data.userFunctions);
        }

        //Load user permissions
        const res2 = await api.getAccount(userId);
        if (res2.status === 200) {
          setPermission(res2.data.roleName);
        }
      })();
    }
  }, [userId]);

  if (!user) {return}

  return (
    <Fragment>
      <ConfirmationDialog
        title='Delete User?'
        text='This will permanently delete the user and cannot be undone.'
        isOpen={modalDelete}
        onClose={ () => setModalDelete(false) }
        onConfirm={handleDelete}
      />
      <StaticWindow
        isOpen={isOpen}
        onClose={onClose}
        sx={{minWidth: '360px'}}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant='h5'>{user.firstName} {user.lastName}</Typography>
          <MultipleWorkFunctionInput
            value={loadedFun.map( el => el)}
            onChange={ e => setLoadedFun(e) }
          />
          <PermissionSelect permission={permission} setPermission={setPermission}/>
          <Box sx={{display: 'flex', gap: 1}}>
            <Button onClick={() => setModalDelete(true)}>Archive User</Button>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button variant='contained' onClick={handleSubmit}>Submit</Button>
          </Box>
        </Box>
      </StaticWindow>
    </Fragment>
  );
}

export { UserEditor }
export default UserEditor;