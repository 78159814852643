function findUserById(users, userId) {
  for (let el of users) {
    if (el.id === userId) {
      return el;
    }
  }
}

function removeUserById(users, userId) {
  return users.filter( el => el.id !== userId );
}


function filterArchived(users) {
  return users.filter( el => el.activeFlag );
}

export { findUserById, removeUserById, filterArchived };

