import { api, defaultCallback } from './api';

async function getApproval(timeRequestId, cb=defaultCallback) {
  const res = await api.get(`approval/getLast/${timeRequestId}`);
  cb(res?.data);
  return await res?.data;
}

function getPending(cb=defaultCallback) {
  api.get(
    `approval/getPending`
    ).then( res => cb(res?.data))
}

async function getAllApprovals(startDate, endDate, lasts=true, cb=defaultCallback) {
  const res = await api.get(
    `approval/getAllApprovals`,
    {
      params: {startDate: startDate, endDate: endDate, lasts: lasts}
    }
  );
  cb(res);

  return res;
}

async function updateApproval(timeRequestId, status, remark='', cb=defaultCallback) {
  const res = await api.post(
    `approval`,
    {
      'status': status,
      'timeRequestId': timeRequestId,
      'remark': remark,
    }
  );
  cb(res);

  return res;
}


export { getApproval, getAllApprovals, getPending, updateApproval }