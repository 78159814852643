import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import {
  Button,
  Paper,
  Box,
  TextField,
  Input,
} from '@mui/material';

function FormPhoneInput({ name, control, label, defaultValue, disabled, type='text', sx={}}) {
  return(
    <Box
      sx={{
        flex: 1,
        ...sx,
      }}
    >
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
      <InputMask
        mask='+49 99 999 99 99'
        maskChar=' ' 
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        { () => (
          <TextField
            helperText={error ? error.message : null}
            error={!!error}
            fullWidth
            label={label}
            variant='outlined'
            type={type}
            disabled={disabled}
          />
        )}
      </InputMask>
      )
    }
    />
    </Box>
  )
}

export { FormPhoneInput };
export default FormPhoneInput;
