import dayjs from "dayjs";

class PostBase {
  constructor(post) {
    this.post     = post;
    this.id       = post.id;
    this.author   = post.createdBy;
    this.date     = post.createdAt;
    this.isActive = post.activeFlag;
  }

  get dateObj() {
    return dayjs(this.date, 'YYYY-MM-DD HH:mm');
  }

  get ageObj() {
    return dayjs.duration( dayjs().diff(this.dateObj) );
  }

  get age() {
    if (this.ageObj.asHours() < 24) {
      return `${Math.floor(this.ageObj.asHours())}h ago`;
    } else {
      return `${Math.floor(this.ageObj.asDays())}d ago`;
    }
  }
}

class Post extends PostBase {
  constructor(post) {
    super(post);
    this.title   = post.title;
    this.content = post.content;
    this.tags    = post.tags;
  }

  get isEdited() {
    return !(this.post.createdAt === this.post.modifiedAt);
  }

}

class Comment extends PostBase {
  constructor(post) {
    super(post);
    this.postId  = post.postId;
    this.comment = post.comment;
  }
}

export { Post, Comment }