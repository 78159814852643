import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Icon,
  Divider,
  CardContent,
  CardMedia,
  Typography,
  ButtonBase,
} from '@mui/material';
import { CardActionArea } from '@mui/material';
import dayjs from 'dayjs';
import partyImage from '../../assets/party_default_image256.jpg'
import * as api from '../../services';
import LockIcon from '@mui/icons-material/Lock';

import ConcertIcon from '@mui/icons-material/MusicNote';
import PartyIcon from '@mui/icons-material/Celebration';
import RentalIcon from '@mui/icons-material/Festival';

function EventCard({ eventObject, selectedEvent, onClick, color = 'primary.dark' }) {
  const { event, eventUsers } = eventObject;
  const size = '96px';
  const theme = useTheme();
  const [image, setImage] = useState(partyImage);
  const [isSelected, setIsSelected] = useState(false);

  function getIcon() {
    switch (event?.eventCategoryName) {
      case 'concert':
        return <ConcertIcon />;
      case 'party':
        return <PartyIcon />;
      case 'rental':
        return <RentalIcon />;
      default:
        return <Icon />;
    }
  }

  useEffect(() => { console.log(eventObject) }, [eventObject]);

  useEffect(() => {
    async function fetchImage(eventId) {
      const img = (await api.getEventPicture(eventId)).data.image;
      if (img && img !== '') {
        setImage(img);
      }
    }
    if (eventObject) {
      fetchImage(eventObject.event.id);
    }
  }, [eventObject]);

  useEffect(() => {
    setIsSelected(event.id === selectedEvent?.event.id)
  }, [selectedEvent]);

  return (
    <Card
      sx={{
        minHeight: size,
      }}
    >
      <ButtonBase
        onClick={() => onClick({ event, eventUsers })}
        sx={{ display: 'flex', justifyContent: 'left', width: '100%' }}
      >
        <CardMedia
          image={image}
          sx={{
            height: size,
            width: size,
            minWidth: size,
            filter: event?.isFinal ? 'blur(2px)' : 'none',
            zIndex: 1,
          }}
        />
        {event?.isFinal && (
          <LockIcon
            fontSize='large'
            sx={{
              position: 'absolute',
              top: 32,
              left: 32,
              zIndex: 2,
            }}
          />
        )}
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            height: size,
            width: '100%',
            gap: '2px',
            paddingLeft: '10px',
            marginLeft: '10px',
            padding: '2px',
          }}
        >
          <Typography variant='h6'>{event.name}</Typography>
          <Divider
            sx={{
              bgcolor: color,
              width: '100%',
              marginTop: '-2px',
              marginLeft: '-8px',
              marginBottom: '10px',
            }}
          />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignContent: 'center',
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography variant='body2'>{dayjs(event.eventDate).format('DD\. MMMM YYYY')}</Typography>
              <Typography variant='body2'>{`${event.startTime}-${event.endTime}`}</Typography>
            </Box>
            {getIcon()}
          </Box>
        </CardContent>
      </ButtonBase>
    </Card>
  );
}

export default EventCard;