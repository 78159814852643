import React, { useEffect } from 'react';
import {
  Box,
  Alert,
  Collapse,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function Notification({ text, isOpen, onClose, severity = 'info', timeOut = 4000 }) {
  useEffect(() => {
    if (isOpen) {
      const timeId = setTimeout(() => {
        onClose();
      }, timeOut);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [isOpen, timeOut, onClose]);

  return (
    <Box
      sx={{
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        paddingBottom: 1,
      }}
    >
      <Collapse in={isOpen}>
        <Alert
          severity={severity}
          action={
            <IconButton
              color='inherit'
              size='small'
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
        >
          {text}
        </Alert>
      </Collapse>
    </Box >
  );
}

export { Notification };
export default Notification;