import React, {Fragment, useEffect, useState, useRef, useContext} from 'react';
import {
  Box,
  Paper,
  Button,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  enUS, frFR, deDE, GridToolbarContainer, GridToolbarQuickFilter, useGridApiRef
} from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import HistoryIcon from '@mui/icons-material/ManageSearch';
import DenyIcon from '@mui/icons-material/Cancel';
import ApproveIcon from '@mui/icons-material/CheckCircle';

import * as api from '../../services';
import { initDateRange, formatDay, calcTotalRequest, monthFromDaterange } from '../../utils/dateUtils';
import { ApprovalIcon } from '../time/ApprovalItem';
import { DateRange } from '../../components/ui/buttons';
import { Loadable } from '../../components/wrapper/Loadable';
import { WorkFunctionAvatar } from '../work-functions/WorkFunctionItem';
import { SecondaryWindow } from '../../components/ui/windows';
import { TimeRequestDetails } from './TimeRequestDetails';
import {DefinitionsContext} from "../../context/DefinitionsContext";
import {CurrentUserContext} from "../../context/CurrentUserContext";
import * as XLSX from 'xlsx';
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function getLocaleText(languageId) {
  switch (languageId) {
    case 1:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
    case 2:
      return deDE.components.MuiDataGrid.defaultProps.localeText;
    case 3:
      return frFR.components.MuiDataGrid.defaultProps.localeText;
    default:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
  }
}
function CustomToolbar({ onExport }) {
  const { translationList } = useContext(DefinitionsContext);

  return (
      <GridToolbarContainer
          sx={{ display: 'flex',
            justifyContent: 'space-between' }}>
        {/* Default Toolbar */}
        <GridToolbar
            csvOptions={{ disableToolbarButton: true }}  // Disable CSV export
            printOptions={{ disableToolbarButton: true }}  // Disable Print export
        />

        {/* Export to Excel Icon + Text */}
        <Box
            onClick={onExport}
            sx={{
              display: 'flex',
              alignItems: 'center',  // Align icon and text vertically
              cursor: 'pointer',
              color: '#00695f',
              marginLeft: -1,
              paddingTop: 0.5,
              '@media (max-width:600px)': {
                paddingLeft: 1.5,
              },
            }}
        >
          <FileDownloadIcon sx={{ marginRight: 1, fontSize: 15 }} />
          <Typography
              variant="body1"
              sx={{
                fontSize: '10px',
                color: '#00695f',
                textTransform: 'uppercase',
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                '@media (max-width:600px)': {
                  fontSize: '10px',
                },
              }}
          >
            {translationList.export}  {/* Export text from translation */}
          </Typography>
        </Box>

        {/* Right side - Quick Filter */}
        <GridToolbarQuickFilter sx={{ marginLeft: 'auto' }} />
      </GridToolbarContainer>
  );
}

const statusMap = {1: 'pending', 2: 'approved', 3: 'denied'}

function TimeReview() {
  const { translationList, workFunctions } = useContext(DefinitionsContext);
  const isLoading = useRef(true);
  const [dateRange, setDateRange] = useState(initDateRange());
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [detail, setDetail] = useState('');
  const [actionCount, setActionCount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);

  async function handleApproval(timeRequstId,  status) {
    setIsLocked(true);
    try {
    await api.updateApproval(timeRequstId, status);
    setActionCount(actionCount + 1);
    } catch (e) {
      console.error(e);
    }
    finally {
      setIsLocked(false);
    }
  }

  function handleMultipleApproval(timeRequestId, status) {
    let tmp;
    for (let reqId of timeRequestId) {
      tmp = data.find( el => el.id === reqId);
      if (tmp.approval.status.name !== status) {
        handleApproval(reqId, status);
      }
      else {
      }
    }
    setSelectedRows([]);
    setShouldUpdate(true);
  }

  useEffect( () => {
    (async () => {
      const times = await api.listTimeRequests(dateRange);
      const users = await api.listUsers();
      const approvals = (await api.getAllApprovals(dateRange[0], dateRange[1]))?.data;
      const events = await api.getEventsBetweenDates(dateRange[0], dateRange[1]);

      if (times === undefined || approvals === undefined) {return;}
      const newData = [];
      for await (let req of times) {
        req.total = calcTotalRequest(req);
        //req.approval =  await api.getApproval(req.id);
        req.approval = approvals.find( a => a.timeRequestId === req.id );
        req.user = await users.find( u => u.id === req.userId);
        req.event = (req.eventId) ? await events.find( e => e.event.id === req.eventId)?.event : null;

        newData.push(req);
      }
      setData(newData);
      isLoading.current = false;
    })()
  }, [dateRange, actionCount]);

  useEffect( () => {
    setShouldUpdate(true);
  }, [dateRange]);

  const apiRef = useGridApiRef();

  const exportToExcel = () => {
    const visibleRows = apiRef.current.getSortedRows(); // Get only visible rows
    const visibleColumns = apiRef.current.getVisibleColumns(); // Get visible columns

    const exportData = visibleRows.map((row) => {
      const rowData = {};

      visibleColumns.forEach((col) => {
        // Create a simulated params object for valueGetter
        const params = {
          row,
          field: col.field,
          getValue: (row, field) => row[field],  // Function to get the value from row
          api: apiRef.current,
          colDef: col,
        };

        let value;

        if (col.valueGetter) {
          value = col.valueGetter(params);
        } else if (col.field) {
          value = row[col.field];
        }

        if(col.field === 'approval'){
          rowData[col.headerName || col.field] = value.status.name;
        }else if(col.field !== '__check__' && col.field !== 'actions'){
          rowData[col.headerName || col.field] = value;
        }


      });
      return rowData;
    });

    // Create Excel sheet
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'TimeReview');

    // Save the Excel file
    XLSX.writeFile(wb, `TimeReview-${monthFromDaterange(dateRange)}.xlsx`);
  };


  const { currentUser } = useContext(CurrentUserContext);
  const localeText = getLocaleText(currentUser.languageId);
  const columns = [
    //{ field: 'userFunctions', headerName: 'Functions', width: 150 },
    //{ field: 'id', headerName: 'Id', width: 60 },
    { field: 'approval', headerName: '', width: 60, renderCell: params => <ApprovalIcon approval={params.row.approval} />},
    { field: 'approvalName', headerName: translationList.status, valueGetter: (params) => params.row.approval.status.name },
    { field: 'total', headerName: translationList.total, width: 70 },
    { field: 'startTime', headerName: translationList.begin, width: 70 },
    { field: 'endTime', headerName: translationList.end, width: 70 },
    { field: 'date', headerName: translationList.date, width: 90, valueGetter: (params) => formatDay(params.value)  },
    { field: 'firstName', headerName: translationList.firstname, valueGetter: params => params.row.user.firstName},
    { field: 'lastName', headerName: translationList.lastname, valueGetter: params => params.row.user.lastName},
    { field: 'function', headerName: translationList.function, width: 110, renderCell: params => <WorkFunctionAvatar workFunctions={[params.row.userFunction]} max={3} size='22px'/>},
    { field: 'functionName', headerName: translationList.functionName, width: 110, valueGetter: params => params.row.userFunction?.name },
    { field: 'event', headerName: translationList.event, width: 110, valueGetter: params => params.row.event && params.row.event.name },
    { field: 'requestType', headerName: 'Type', width: 70, valueGetter: params => params.row.requestType?.name},
    {
      field: 'actions',
      headerName: translationList.actions,
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <Box >
        <GridActionsCellItem
          icon={<ApproveIcon />}
          disabled={params.row.approval.status.name !== 'pending' || isLocked}
          onClick={() => handleApproval(params.row.id, 'approved')}
          label={translationList.approve.toLowerCase()}
        />
        <GridActionsCellItem
          icon={<DenyIcon />}
          disabled={params.row.approval.status.name !== 'pending' || isLocked}
          onClick={() => handleApproval(params.row.id, 'denied')}
          label={translationList.deny.toLowerCase()}
        />
        <GridActionsCellItem
          icon={<HistoryIcon />}
          onClick={() => {setDetail(params.row); setModalDetail(true);}}
          label={translationList.history.toLowerCase()}
        />
      </Box>
    ),
  },
];


return (
  <Fragment>
    <SecondaryWindow
      title='Time Request Details'
      isOpen={modalDetail}
      onClose={ () => setModalDetail(false) }
      text={detail}
    >
      <TimeRequestDetails data={detail} />
    </SecondaryWindow>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
      <Paper elevation={2} sx={{display: 'flex', justifyContent: 'space-between', padding: 1}}>
        <Box sx={{display: 'flex', gap: 1}}>
          <Button
            onClick={ () => handleMultipleApproval(selectedRows, 'denied')}
            variant='outlined'
            color='error'
            disabled={selectedRows.length === 0}
          >
            {translationList.deny}
          </Button>
          <Button
            onClick={ () => handleMultipleApproval(selectedRows, 'approved')}
            variant='outlined'
            color='success'
            disabled={selectedRows.length === 0}
          >
            {translationList.approve}
          </Button>
        </Box>
        <DateRange onRangeChange={ (t0, t1) => {
          setDateRange([t0, t1]);
          isLoading.current = true;
          }}
        />
      </Paper>

      <Loadable isLoading={isLoading.current}>
        <DataGrid
          apiRef={apiRef}
          localeText={localeText}
          autoHeight={true}
          rows={data}
          columns={columns}
          checkboxSelection
          disableRowSelectionOnClick
          pageSizeOptions={[20, 50, 100]}
          density='compact'
          rowSelectionModel={ selectedRows }
          isRowSelectable={ param => param.row.approval.status.name === 'pending'}
          onRowSelectionModelChange={ selection => setSelectedRows(selection) }
          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            toolbar: {
              onExport: exportToExcel,
              showQuickFilter: true,
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                approvalName: false,
                userFunctionNames: false,
                requestType: false,
              },
            },
            filter: {
              filterModel: {
                items: [{ field: 'approvalName', operator: 'contains', value: 'pending'}],
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          sx={{
            height: '100%',
            width: '100%',
            '& .MuiDataGrid-virtualScroller': {
              overflowX: 'auto',
              '&::-webkit-scrollbar': {
                height: '4px', // Adjust height for horizontal scrollbar
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'grey', // Set thumb color to black
                borderRadius: '4px', // Optional: round the corners
              },
              '&::-webkit-scrollbar-track': {
                background: 'transparent', // Optional: track background color
              },
            },
          }}
        />
      </Loadable>
    </Box>
    </Fragment>
  );
}

export { TimeReview }
export default TimeReview;