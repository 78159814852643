import React, { useState } from 'react';
import {
  Box,
  Fab,
  Paper,
  Divider,
} from '@mui/material';
import { useTheme } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import PlaceholderIcon from '@mui/icons-material/PermContactCalendar';
import { AddFab, DateRange } from '../buttons';

function ListContainer({ children, outline = true, sx = {} }) {
  const { container: { scroll }, palette: { grey } } = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        {...scroll}
        sx={{
          paddingY: '6px',
          paddingLeft: '6px',
          height: '100%',
          minHeight: '120px',
          width: '100%',
          gap: '6px',
          border: outline ? 1 : 0,
          borderRadius: outline ? '6px' : 0,
          borderColor: grey[700],
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
          },
          ...sx,
        }}
      >
        {children}
      </Paper>
    </Box>
  );
}

function EditableListContainer({ onAdd = undefined, outline = true, isDisabled = false, children, icon = 'add', sx = {} }) {
  function getFabIcon() {
    switch (icon) {
      case 'add':
        return <AddIcon />;
      case 'placeholder':
        return <PlaceholderIcon />;
      default:
        return <AddIcon />;
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ListContainer outline={outline} sx={{ ...sx }}>
        {children}
        <Box minHeight='16px' />
      </ListContainer>
      <Box sx={{ width: '100%' }}>
        <Divider />
      </Box>
      {!isDisabled && onAdd ? (
        <Fab
          color='secondary'
          onClick={onAdd}
          sx={{
            marginTop: '-30px',
          }}
        >
          {getFabIcon()}
        </Fab>
          ) : (
          <Box sx={{ minHeight: '26px', bgcolor: '#800' }} />
      )}
        </Box>
      );
}

      function DateRangeListContainer({markedDays, selectedDate, onDateSelect, onRangeChange, onAdd, isDisabled = false, children, sx = {}}) {
  return (
      <Paper
        id='date-range-list-container'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          paddingY: '10px',
          width: '100%',
          height: '100%',
        }}
      >
        <Box>
          <DateRange
            selectedDate={selectedDate}
            onDateSelect={onDateSelect}
            onRangeChange={onRangeChange}
            markedDays={markedDays}
          />
          <Divider sx={{
            paddingTop: '10px',
          }}
          />
        </Box>
        <EditableListContainer
          outline={false}
          onAdd={onAdd}
          isDisabled={isDisabled}
          sx={{
            ...sx,
          }}
        >
          {children}
        </EditableListContainer>
      </Paper>
      )
}

      function AddableListContainer({onAdd, children, sx = {}}) {
  return (
      <Box
        id='addable-list-container'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingY: '10px',
          width: '100%',
          height: '100%',
          gap: 1,
        }}
      >
        <ListContainer outline={false} sx={sx}>
          {children}
          <Box minHeight='16px' />
        </ListContainer>
        {onAdd &&
          <AddFab
            color='primary'
            onClick={onAdd}
            sx={{
              marginTop: '-30px',
            }}
          />
        }
      </Box>
      );
}

      export default ListContainer;
      export {
        DateRangeListContainer,
        ListContainer,
        EditableListContainer,
        AddableListContainer,
}