import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { CurrentUserContext } from '../../context/CurrentUserContext';

const PrivateRoute = ({ children }) => {
  const { currentUser } = useContext(CurrentUserContext);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;