import React, { Fragment, useContext, useState, useEffect } from 'react';
import {
  Box,
  Select,
  MenuItem,
  InputLabel,
  ButtonBase,
  Typography,
  FormControl,
} from '@mui/material';
import { 
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import { useTheme } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { EventContext } from '../../context/EventContext';
import { DefinitionsContext } from '../../context/DefinitionsContext';
import { CancelConfirm } from '../../components/ui/buttons';
import { stringToDayjs } from '../../utils/dateUtils';
import { UserAvatar } from '../../features/users/UserAvatar';
import { AssigneeSelect } from './AssigneeSelect';
import { SecondaryWindow } from '../../components/ui/windows';
import { WorkFunctionInput } from '../work-functions/WorkFunctionInput';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
/* Edit a single Assignee that was clicked on. Set start and end time and so on */
function AssigneeEdit({ assignee, onClose }) {
  const { translationList } = useContext(DefinitionsContext);
  const { container: {item}, palette: {grey} } = useTheme();
  const { responsibilities, workFunctions } = useContext(DefinitionsContext);
  const { assignees, setAssignees } = useContext(EventContext);
  const [loadedAssignee, setLoadedAssignee] = useState(assignee);
  const [workFun, setWorkFun] = useState(assignee.userFunctionName ? assignee.userFunctionName : '');
  const [startTime, setStartTime] = useState(
    stringToDayjs(dayjs().format('YYYY-MM-DD'), assignee.startTime)
  );
  const [endTime, setEndTime] = useState(
    stringToDayjs(dayjs().format('YYYY-MM-DD'), assignee.endTime)
    );
  const [responsibility, setResponsibility] = useState(assignee.responsibility ? assignee.responsibility : 'none');
  
  const [showAssign, setShowAssign] = useState(false);

  function handleSubmit() {
    const tmp = loadedAssignee;
    tmp.userFunctionName = workFun;
    tmp.userFunctionId = workFunctions.filter( el => el.name === workFun).id;
    tmp.setStartTime(startTime.format('HH:mm'));
    tmp.setEndTime(endTime.format('HH:mm'));
    tmp.setResponsibility(responsibility);
    setAssignees([...assignees.filter( el => el.id !== assignee.id), tmp]);

    onClose();
  }

  function handleAssigneeSelect(user) {
    setShowAssign(false);
    const newAssignee = loadedAssignee.setUser(user);
    setLoadedAssignee(newAssignee);
  }

  return (
    <Fragment>
      <SecondaryWindow
        isOpen={showAssign}
        onClose={ () => setShowAssign(false) }
        hideBackdrop={true}
        variant='secondary'
      >
        <AssigneeSelect
          loadedAssignee={loadedAssignee}
          initialFilter={workFun}
          onSelect={handleAssigneeSelect}
        />
      </SecondaryWindow>
      <Box
        gap={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          gap={1}
          sx={{
            display: 'flex',
            flex: 1,
          }}
        >
        <Box
          sx={{
            display: 'flex',
            flex: 8,
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '70px',
            border: 1,
            borderColor: grey[700],
            borderTopLeftRadius: '80px',
            borderBottomLeftRadius: '80px',
            paddingX: 1,
            paddingY: 2,
            gap: 1,
          }}
          >
          <UserAvatar
            user={loadedAssignee.user}
            sx={{
              width: '56px',
              height: '56px',
            }}
          />

          <ButtonBase
            onClick={()=>{setShowAssign(true)}}
            sx={{
              width: '100%',
              height: '70px',
            }}
          >

            <Box
              sx={{
                width: '100%',
                padding: 1,
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                { (loadedAssignee.user !== null) &&
                  <Typography variant='body2'>{`${loadedAssignee.user.firstName} ${loadedAssignee.user.lastName}`}</Typography>
                }
              </Box>
            </Box>

          </ButtonBase>
        </Box>

        <FormControl fullWidth sx={{flex: 4}}>
          <InputLabel>{translationList.responsability}</InputLabel>
          <Select
            label={translationList.responsability}
            value={responsibility}
            onChange={ e => setResponsibility(e.target.value)}
            varian='outlined'
            sx={{
              height: '70px',
            }}
          >
            {responsibilities.map( (el) => {
              return <MenuItem key={el.id} value={el.name}>{translationList[el.name]}</MenuItem> } )}
          </Select>
        </FormControl>

        </Box>

        <WorkFunctionInput
          value={workFun}
          onChange={setWorkFun}
        />

        <Box
          sx={{
            display: 'flex',
            gap: 1,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              timezone="Europe/Zurich"
              ampm={false}
              label={translationList.begin}
              value={startTime}
              onChange={ (newTime) => setStartTime(newTime) }
            />
            <TimePicker
              timezone="Europe/Zurich"
              ampm={false}
              label={translationList.end}
              value={endTime}
              onChange={ (newTime) => setEndTime(newTime) }
            />
          </LocalizationProvider>
        </Box>
        <CancelConfirm onConfirm={handleSubmit} onCancel={onClose}/>
      </Box>
    </Fragment>
  );
}

export { AssigneeEdit }
export default AssigneeEdit;