import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import { PostItem } from './PostItem';
import { PostEditor } from './PostEditor';

function PostInspector({ post, onClose, onChange, topicId }) {
  const [isEdit, setIsEdit] = useState(false);
  if (!post) { return; }

  if (isEdit) {
    return (
      <Box>
        <PostEditor
          defaultPost={post?.post}
          onChange={onChange}
          onClose={onClose}
        />
      </Box>);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <PostItem
        post={post}
        onChange={onChange}
        onClose={onClose}
        onEdit={() => setIsEdit(true)}
      />
    </Box>
  );
}

export { PostInspector }
export default PostInspector;