import React, { useContext } from 'react';
import {
  Box,
  Divider,
} from '@mui/material';
import { PasswordChanger } from './PasswordChanger';
import { LanguageSelect } from './LanguageSelect';
import { CurrentUserContext } from '../../context/CurrentUserContext';

function AccountSettings() {
  const { currentUser } = useContext(CurrentUserContext);
  const divider = <Divider sx={{marginTop: '10px', marginBottom: '15px'}}/>
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: 1,
      }}
    >
      <PasswordChanger user={currentUser}/>
      {divider}
      <LanguageSelect user={currentUser}/>
    </Box>
  );
}


export { AccountSettings }
export default AccountSettings;