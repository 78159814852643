import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
} from '@mui/material';

import { StaticWindow } from '../../components/ui/windows/StaticWindow';
import { DefinitionsContext } from "../../context/DefinitionsContext";
import { addUser } from '../../services/userAPI';
import { Notification } from '../../components/ui/Notification';
import { TextInput, useAreInputsValid } from '../../components/inputs';

function UserCreator({ isOpen, onClose, onChange }) {
  const { translationList } = useContext(DefinitionsContext);
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [notify, setNotify] = useState({ text: '', severity: 'info' });
  const [showNotify, setShowNotify] = useState(false);
  const [isValid, onValidChange] = useAreInputsValid();
  const [isLocked, setIsLocked] = useState(false);

  function resetState() {
    setFirstName('');
    setLastName('');
    setEmail('');
    setShowNotify(false);
  }

  function handleClose() {
    resetState();
    onClose();
  }

  async function handleSubmit() {
    try {
      setIsLocked(true);
      await addUser(firstName, lastName, email);
      setNotify({ text: translationList.userCreated, severity: 'success' });
      setShowNotify(true);
      onChange();
    } catch (error) {
      switch (error.response?.data?.error) {
        case 'wrong_format':
          setShowNotify(true);
          setNotify({ text: error.response?.data?.message, severity: 'error' });
          break;
        default:
          setNotify({ text: 'An unexpected error occurred', severity: 'error' });
          setShowNotify(true);
          break;
      }
    }
    finally {
      setIsLocked(false);
    }
  }

  return (
    <StaticWindow
      title={translationList.createNewUser}
      isOpen={isOpen}
      onClose={handleClose}
      sx={{

      }}
    >
      <Notification isOpen={showNotify} onClose={() => setShowNotify(false)} text={notify.text} severity={notify.severity} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '360px',
          gap: 1,
          '@media (max-width:600px)': {
            minWidth: '100px',
          },
        }}
      >
        <TextInput
          label={translationList.firstname}
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          onValidChange={onValidChange}
        />
        <TextInput
          label={translationList.lastname}
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          onValidChange={onValidChange}
        />
        <TextInput
          label={translationList.email}
          value={email}
          onChange={e => setEmail(e.target.value)}
          onValidChange={onValidChange}
        />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1,
          }}
        >
          <Button
            variant='outlined'
            onClick={handleClose}
          >
            {translationList.cancel}
          </Button>
          <Button
            disabled={!firstName || !lastName || !email || !isValid || isLocked}
            variant='contained'
            onClick={handleSubmit}
          >
            {translationList.save}
          </Button>
        </Box>
      </Box>
    </StaticWindow>
  );
}

export { UserCreator }
export default UserCreator;