import { TextField, } from '@mui/material';
import {useState, useEffect, useContext} from 'react';
import { set } from 'react-hook-form';
import {DefinitionsContext} from "../../context/DefinitionsContext";


function TextInput({ label, value, onChange, onValidChange, isRequired, max=255, disabled=false, sx={}, ...props }) {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const { translationList } = useContext(DefinitionsContext);

  useEffect(() => {
    if (value.length > max) {
      setError(true);
      onValidChange && onValidChange(label, false);
      setHelperText(translationList.charNoLongerThan.replace('{max}', max));
    }
    else if (isRequired && value.length === 0) {
      setError(true);
      onValidChange && onValidChange(label, false);
      setHelperText(translationList.fieldRequired);
    }
    else {
      setError(false);
      onValidChange && onValidChange(label, true);
    }
  }, [isRequired, value, max, label, onValidChange]);

  return (
    <TextField
      disabled={disabled}
      label={label}
      value={value}
      onChange={ e => {if (onChange) onChange(e)} }
      error={error}
      helperText={error ? helperText : null}
      type='text'
      fullWidth
      {...props}
    />
  );
}

export default TextInput;
export { TextInput };