import React, { useState, useEffect, useContext } from 'react';
import {
  Badge,
  Box, DialogActions,
  Typography,
} from '@mui/material';


import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

import { dateToRange } from '../../utils/dateUtils';

import dayjs from 'dayjs';
import 'dayjs/locale/fr-ch';
import 'dayjs/locale/de-ch';
import 'dayjs/locale/en-gb';
import { CurrentUserContext } from '../../context/CurrentUserContext';
import {getStringLanguage} from '../../data/dayjsConfig'
import {deDE, enUS, frFR} from "@mui/x-data-grid";

function getLocaleText(languageId) {
  switch (languageId) {
    case 1:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
    case 2:
      return deDE.components.MuiDataGrid.defaultProps.localeText;
    case 3:
      return frFR.components.MuiDataGrid.defaultProps.localeText;
    default:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
  }
}

function EventDay(props) {

  //badgeContent={'🔵'}
   const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? '🎈' : undefined}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  );
}

function EventCaldendar({ markedDays=[], selectedDate=dayjs(), onDateSelect=()=>{}, onRangeChange=()=>{} }) {
  const [date, setDate] = useState(dayjs());
  const [highlightedDays, setHighlightedDays] = useState([]);
  const { currentUser } = useContext(CurrentUserContext);
  const locale = getStringLanguage(currentUser.languageId);
  dayjs.locale(locale); // Set the locale for dayjs
  const localeText = getLocaleText(currentUser.languageId);


  function handleRangeChange(date) {
    const [startDate, endDate] = dateToRange(date);
    onRangeChange(startDate, endDate);
  }

  useEffect( () => {
    handleRangeChange( dayjs() );
  }, []);

  useEffect( () => {
    const days = [];
    markedDays.map( el => days.push(dayjs(el.eventDate).date()) );
    setHighlightedDays(days);
  }, [markedDays]);

  useEffect( () => {
    onDateSelect(date);
  }, [date]);

  return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale} localeText={localeText} >
          <MobileDatePicker
              value={selectedDate}
              views={['month', 'year']}
              onChange={(newDate) => setDate(newDate)}
              onMonthChange={(date) => handleRangeChange(date)}
              onYearChange={(date) => handleRangeChange(date)}
              slots={{
                day: EventDay,
              }}
              slotProps={{
                day: {
                  highlightedDays,
                },
              }}
          />
      </LocalizationProvider>
  );
}



export default EventCaldendar;