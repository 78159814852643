import React, { useState, useEffect, Fragment } from 'react';
import dayjs from 'dayjs';

import EventCard from '../events/EventCard';
import EventEditor from './EventEvalEditor';
import { PageContainer, DateRangeListContainer } from '../../components/ui/containers';
import { dateToRange } from '../../utils/dateUtils';
import * as api from '../../services/eventAPI';
import useViewportHeight from "../../styles/UseViewportHeight";

function EventEvaluation() {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedEventId, setSelectedEventId] = useState();
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [isEditor, setIsEditor] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const vh = useViewportHeight();

  function sortEvents(events) {
    try {
      return events.sort((a, b) => {
        const dateA = new Date(a.event.eventDate);
        const dateB = new Date(b.event.eventDate);
        return dateA - dateB;
      });
    } catch (error) {
      console.error('Error sorting events:', error);
    }
  };

    //Load events when one has been created
    function handleUpdate() {
      const [t0, t1] = dateToRange(selectedDate);
      api.getEventsBetweenDates(t0, t1, (e) => setEvents(sortEvents(e)));
    };

    //Change the month to be displayed
    function onRangeChange(startDate, endDate) {
      api.getEventsBetweenDates(startDate, endDate, (e) => setEvents(sortEvents(e)));
      setSelectedDate([startDate, endDate]);
    }

    //Open the editor if event item is selected
    useEffect(() => {
      if (selectedEvent) {
        setIsEditor(true);
      };
    }, [selectedEvent])

    useEffect(() => {
      if (isCreateMode) {
        setIsEditor(true);
      }
    }, [isCreateMode]);

    if (!events) { return; }

    return (
      <Fragment>
        {/*--Dialogs-----------------------------------------------------------*/}
        <EventEditor
          eventId={selectedEventId}
          createMode={isCreateMode}
          isOpen={isEditor}
          onClose={() => { setIsEditor(false); setIsCreateMode(false); }}
          onUpdate={handleUpdate}
        />

        {/*--Content-----------------------------------------------------------*/}
        <PageContainer>
          <DateRangeListContainer
            onAdd={() => setIsCreateMode(true)}
            onRangeChange={onRangeChange}
            onDateSelect={(newDate) => { setSelectedDate(newDate); }}
            selectedDate={dayjs(selectedDate)}
            markedDays={events}
            isDisabled={true}
            sx={{
              height: `${vh * 0.8}px`,
              overflowY: events.length > 9 ? 'auto' : 'visible',
              '@media (max-width:600px)': {
                height: `${vh * 0.7}px`,
                overflowY: events.length > 4 ? 'auto' : 'visible',
              },
            }}
          >
            {events.map(el =>
              <EventCard
                eventObject={el}
                selectedEvent={selectedEvent}
                onClick={(newEvent) => {
                  setSelectedEvent(newEvent);
                  setSelectedEventId(newEvent.event.id);
                }}
                color='secondary.dark'
                key={el.event.id}
              />
            )}
          </DateRangeListContainer>
        </PageContainer>
      </Fragment>
    );
  }

  export default EventEvaluation;