import React, {useState, useEffect, useContext} from 'react';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import { getUser } from '../../services';
import {DefinitionsContext} from "../../context/DefinitionsContext";
import { WorkFunctionChip } from '../work-functions/WorkFunctionItem';

// Assuming DetailField is a simple component that displays label and value
const DetailField = ({ label, value }) => (
  <Grid item xs={12}>
    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
      {label}
    </Typography>
    <Typography variant="body1">{value || 'N/A'}</Typography>
  </Grid>
);

const DetailFieldChild = ({ label, children }) => (
  <Grid item xs={12}>
    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
      {label}
    </Typography>
    {children}
  </Grid>
);

function TimeRequestDetails({ data, approvedBy }) {
  const [aprovee, setAprovee] = useState('');
  const { translationList } = useContext(DefinitionsContext);

  useEffect(() => {
    const userId = data?.approval?.approvedBy;
    if (!userId) {
      setAprovee('');
      return;
    };
    (async () => {
      const res = await getUser(userId);
      const name = `${res?.data?.firstName} ${res?.data?.lastName}`;
      setAprovee(name);
    })();
    return () => setAprovee('');
  }, [data]);

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', my: 2 }}>
      <Grid container spacing={2}>
        <DetailField label={translationList.status} value={data?.approval?.status?.name} />
        <DetailField label={translationList.approvedBy} value={aprovee} />
        <DetailField label={translationList.begin} value={data?.startTime} />
        <DetailField label={translationList.end} value={data?.endTime} />
        <DetailFieldChild label={translationList.function}>
          <WorkFunctionChip workFunction={data?.userFunction?.name} />
        </DetailFieldChild>
        <DetailField label={translationList.description} value={data?.description} />
      </Grid>
    </Box>
  );
}

export default TimeRequestDetails;
export { TimeRequestDetails };