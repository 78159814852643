import React, { useState } from 'react';
import {
  Box,
  Modal,
  Paper,
} from '@mui/material';

import CloseButton from '../buttons/CloseButton';
import { backdropProp, sxModal } from '../../../styles/styles';
import { DialogContainer } from '../containers';

function Window({ isOpen, onClose, children, sx={} }) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={ backdropProp }
    >
      <Paper
        sx={{
          ...sxModal,
          ...sx,
        }}
      >
        <CloseButton onClose={onClose}/>
        {children} 
      </Paper>
    </Modal>
  );
}

export { Window }
export default Window;