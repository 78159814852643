import axios from 'axios';
import config from '../data/config.json';
//import { Buffer } from 'buffer';

const username = 'l_ouederni';
const password = 'admin';
const auth = {username: username, password: password}
const defaultCallback = (res) => {};
//const token = `${username}:${password}`;
//const encodedToken = Buffer.from(token).toString('base64');


async function getAccount(userId, cb=defaultCallback) {
  const res = await axios.get(
    `${config.api}accounts/${userId}`,
    {auth: auth}
  )
  cb(res);

  return res;
}

async function updatePermissions(userId, permission, cb=defaultCallback) {
  const res = await axios.put(
    `${config.api}accounts/updateAccountRoles`,
    {
      roleName: permission,
      userId: userId,
    },
  {auth: auth}
  )
  cb(res);

  return res;
}

export {
  getAccount,
  updatePermissions,
};