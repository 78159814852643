import React, { useState } from 'react';
import {
  Box,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function CloseButton({ onClose, sx }) {
  return (
    <Box
      display='flex'
      justifyContent='flex-end'
      marginBottom='12px'
      {...sx}
    >
      <Box margin={-3}>
        <IconButton
          color='error'
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

function DialogCloseButton({ onClose }) {
  return (
    <Box
      display='flex'
      justifyContent='flex-end'
      marginBottom='12px'
    >
      <Box
        sx={{
          marginX: 0,
          marginY: 0, 
        }}
      >
        <IconButton
          color='error'
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default CloseButton;
export { CloseButton, DialogCloseButton }