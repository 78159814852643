import React, { useState, useEffect, useContext } from 'react';
import * as api from '../../services';
import { CircularProgress } from '@mui/material';
import PersonalDataLogic from './PersonalDataLogic';
import { DefinitionsContext } from "../../context/DefinitionsContext";

export function checkIsComplete(data) {
  if (!data) {
    return false;
  }

  // Check phone and mobile numbers
  const hasPhoneNumber = data.phoneNumber && data.phoneNumber !== '';
  const hasMobileNumber = data.mobileNumber && data.mobileNumber !== '';
  const hasPhoneOrMobile = hasPhoneNumber || hasMobileNumber;

  const result = (
      typeof data.firstName === 'string' && data.firstName.trim() !== '' &&
      typeof data.lastName === 'string' && data.lastName.trim() !== '' &&
      typeof data.street === 'string' && data.street.trim() !== '' &&
      typeof data.zip === 'string' && data.zip.trim() !== '' &&
      typeof data.location === 'string' && data.location.trim() !== '' &&
      typeof data.birthdate === 'string' && data.birthdate !== '1900-01-01' &&
      typeof data.email === 'string' && data.email.trim() !== '' &&
      typeof data.entryDate === 'string' && data.entryDate.trim() !== '' &&
      hasPhoneOrMobile && // Ensure either phoneNumber or mobileNumber is present
      typeof data.correspondanceType === 'string' && data.correspondanceType.trim() !== '' &&
      typeof data.ahvNumber === 'string' && data.ahvNumber.trim() !== '' &&
      data.numberOfKids !== null &&
      typeof data.nationality === 'string' && data.nationality.trim() !== '' &&
      typeof data.workingPermit === 'string' && data.workingPermit.trim() !== '' &&
      typeof data.taxCanton === 'string' && data.taxCanton.trim() !== '' &&
      typeof data.otherEmployer === 'string' && data.otherEmployer.trim() !== ''
  );

  console.log('Result:', result);
  return !!result;
}


function PersonalDataService({ userId, disabled, setIsComplete, setNotification }) {
  const [data, setData] = useState();
  const [image, setImage] = useState(null);
  const { translationList } = useContext(DefinitionsContext);

  // Function to handle form submission
  async function handleSubmit(submitData) {
    try {
      // Send updated data to the API
      const updateResponse = await api.updateUser(userId, submitData);

      // Notify success
      if (updateResponse?.status === 200) {
        setNotification({ text: translationList.userDataUpdToDate, severity: 'success' });

        // Fetch updated user data
        const updatedDataResponse = await api.getUser(userId);
        if (updatedDataResponse?.status === 200 && updatedDataResponse?.data) {
          const updatedData = updatedDataResponse.data;
          setData(updatedData);

          // Check completeness of the updated data and update state to handle the icon
          if (checkIsComplete(updatedData)) {
            setIsComplete(true); // Mark personal data as complete
          } else {
            setIsComplete(false); // Mark personal data as incomplete
          }
        }
      }
    } catch (e) {
      // Handle errors
      switch (e?.response?.data?.error) {
        case 'wrong_format':
          setNotification({ text: `${translationList.wrongFormat}: ${e.response.data.message}`, severity: 'error' });
          break;
        default:
          setNotification({ text: `${translationList.errorOccured}: ${e?.response?.data?.message}`, severity: 'error' });
          break;
      }
    }

    // Handle image upload if there is an image
    if (image) {
      try {
        await api.addProfilePicture(userId, image);
      } catch (error) {
        console.error('Error uploading profile picture:', error);
      }
    }
  }

  // Fetch user data and profile picture on component mount or when userId changes
  useEffect(() => {
    if (userId) {
      (async () => {
        try {
          const res = await api.getUser(userId);
          if (res?.status === 200 && res?.data) {
            setData(res.data);
          }
          const res2 = await api.getProfilePicture(userId);
          if (res2?.data?.image) {
            setImage(res2.data.image);
          }
        } catch (error) {
          console.error('Error fetching user or profile picture:', error);
        }
      })();
    }
  }, [userId]);

  // Display a loading indicator while data is being fetched
  if (!data) return <CircularProgress />;

  // Default values for the form
  const defaultValues = {
    firstName: data.firstName ?? '',
    lastName: data.lastName ?? '',
    street: data.street ?? '',
    zip: data.zip ?? '',
    location: data.location ?? '',
    birthdate: data.birthdate ?? '1900-01-01',
    email: data.email ?? '',
    entryDate: data.entryDate ?? '',
    phoneNumber: data.phoneNumber ?? '',
    mobileNumber: data.mobileNumber ?? '',
    correspondanceType: data.correspondanceType ?? 'email',
    ahvNumber: data.ahvNumber ?? '',
    numberOfKids: data.numberOfKids ?? 0,
    userFunctions: data.userFunctions ?? [],
    nationality: data.nationality ?? '',
    workingPermit: data.workingPermit ?? '',
    taxCanton: data.taxCanton ?? '',
    otherEmployer: data.otherEmployer ?? '',
  };

  if (!defaultValues) return <CircularProgress />;

  return (
      <PersonalDataLogic
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          image={image}
          setImage={setImage}
          userId={userId}
          disabled={disabled}
          sx={{
            width: '100%',
            height: '100%',
          }}
      />
  );
}

export default PersonalDataService;
