import React, {useContext, useState} from 'react';
import {
  Box,
  Button,
  Typography,
  DialogActions,
} from '@mui/material';
import {DefinitionsContext} from "../../../context/DefinitionsContext";

function CancelConfirm({ onCancel, onConfirm, cancelLabel='Cancel', confirmLabel='Confirm', disabled=false }) {
    const { translationList } = useContext(DefinitionsContext);

  return (
    <DialogActions>
      <Button variant='outlined' onClick={onCancel}>{translationList.cancel}</Button>
      <Button variant='contained' onClick={onConfirm} disabled={disabled}>{translationList.confirm}</Button>
    </DialogActions>
  );
}

export { CancelConfirm }
export default CancelConfirm;