import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Paper,
  Box,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { stringToDayjs } from '../../utils/dateUtils';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {getStringLanguage} from '../../data/dayjsConfig'
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/fr-ch';
import 'dayjs/locale/de-ch';
import 'dayjs/locale/en-gb';

dayjs.extend(utc);
dayjs.extend(timezone);


function FormDateInput({ name, control, label, defaultValue, disabled=false, sx={}, languageId }) {
    dayjs.locale(getStringLanguage(languageId));
    return(
    <Box
      sx={{
        flex: 1,
        ...sx,
      }}
    >
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          helperText={error ? error.message : null}
          error={!!error}
          onChange={ e => onChange( e.format('YYYY-MM-DD') )}
          value={dayjs(value)}
          fullWidth
          timezone="Europe/Zurich"
          format={'DD.MM.YYYY'}
          label={label}
          variant='outlined'
          disabled={disabled}
          sx={{width: '100%'}}
        />
      </LocalizationProvider>
      )
    }
    />
    </Box>
  )
}

export { FormDateInput };
export default FormDateInput;
