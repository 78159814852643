import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Select,
  FormControl,
  MenuItem,
  Typography,
} from '@mui/material';
import { logout, getAllLanguages, changeUserLanguage } from '../../services';
import { DefinitionsContext } from "../../context/DefinitionsContext";
import { Notification } from '../../components/ui/Notification';

function LanguageSelect({ user }) {
  const [languageId, setLanguageId] = useState(user.languageId);
  const [languages, setLanguages] = useState([]);
  const { translationList } = useContext(DefinitionsContext);
  const [notify, setNotify] = useState({ text: '', severity: 'info' });
  const [isNotify, setIsNotify] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectDisabled, setSelectDisabled] = useState(false);

  async function handleLanguageChange(e) {
    setIsSubmitted(true);
    setSelectDisabled(true);
    try {
      const res = await changeUserLanguage(user.id, languageId);
      if (res.status === 200) {
        setNotify({ text: 'Language changed, you need to log in again.', severity: 'success' });
        setIsNotify(true);
        setTimeout(() => {
          logout();
          localStorage.removeItem('currentUser');
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      // TODO handle error
    } finally {
      setIsSubmitted(false);
    }
  }

  useEffect(() => {
    if (!user) return;

    (async () => {
      const langs = await getAllLanguages();
      setLanguages(langs);
    })();
  }, [user]);

  return (
      <FormControl>
        <Notification text={notify.text} severity={notify.severity} isOpen={isNotify} onClose={() => setIsNotify(false)} />
        <Typography variant='h6'>{translationList.changeLanguage}</Typography>
        <Box
            sx={{
              display: 'flex',
              gap: 1,
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
        >
          <Select
              fullWidth
              id='language-select'
              value={languageId}
              onChange={(e) => setLanguageId(e.target.value)}
              disabled={selectDisabled}
          >
            {languages.map((lang) => (
                <MenuItem key={lang.id} value={lang.id}>
                  {lang.keyword}
                </MenuItem>
            ))}
          </Select>
          <Button
              disabled={selectDisabled || (languageId === user.languageId) || isSubmitted}
              variant='contained'
              onClick={handleLanguageChange}
          >
            {translationList.submit}
          </Button>
        </Box>
      </FormControl>
  );
}

export { LanguageSelect };
export default LanguageSelect;
