// translationMapper.js
import { getAllTranslation } from '../services';
import axios from "axios";
import config from "../data/config.json";
const translations = {};

async function mapTranslations(languageId) {
    let allTranslations = {};
    try {
        allTranslations = await getAllTranslation(languageId);
        allTranslations.forEach(translation => {
            translations[translation.keyword] = translation.description;
        });
    } catch (error) {
        console.error("Error mapping translations:", error);
    }

    return translations;
}


export {
    mapTranslations,
}