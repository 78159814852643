import React, {useContext, useState} from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';

import { SecondaryWindow } from '../ui/windows';
import {DefinitionsContext} from "../../context/DefinitionsContext";

function ConfirmationDialog({title='Are you sure?', text, isOpen, onClose, onConfirm, children}) {
 const { translationList } = useContext(DefinitionsContext);
  return (
    <SecondaryWindow
        isOpen={isOpen}
        onClose={onClose}
        title={translationList.areYouSure}
      >
          <Typography
            variant='body'
          >
            {text}
          </Typography>
            {children}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 1,
              marginTop: '30px',
            }}
          >
            <Button
              variant='outlined'
              onClick={onClose}
            >
                {translationList.cancel}
            </Button>
            <Button
              variant='contained'
              color='error'
              onClick={ () => {
                onConfirm(); 
                onClose();
                }
              }
            >
                {translationList.confirm}
            </Button>
        </Box>
      </SecondaryWindow>
  );
}

export { ConfirmationDialog }
export default ConfirmationDialog;