import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  TextField,
} from '@mui/material';

function FormNumberInput({ name, control, label, defaultValue, onValideChange, disabled, min = null, max = null, sx = {} }) {
  return (
    <Box
      sx={{
        flex: 1,
        ...sx,
      }}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          validate: (value) => {
            if (min !== null && value < min) {
              onValideChange(name, false);
              return `Value should be at least ${min}`
            };
            if (max !== null && value > max) {
              onValideChange(name, false);
              return `Value should be at most ${max}`;
            }
            onValideChange(name, true);
            return true;
          }
        }}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => (
          <TextField
            helperText={error ? error.message : null}
            error={!!error}
            onChange={e => onChange(e)}
            value={value}
            fullWidth
            label={label}
            variant='outlined'
            type='number'
            disabled={disabled}
            InputProps={{
              sx: {
                '& input[type=number]': {
                  '-moz-appearance': 'textfield',
                  '-webkit-appearance': 'none',
                  'margin': 0,
                },
                '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  'margin': 0,
                },
              }
            }}
          />
        )
        }
      />
    </Box>
  )
}

export { FormNumberInput };
export default FormNumberInput;
