import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { ChartContainer, BarPlot } from '@mui/x-charts';
import { useTheme } from '@mui/material';

import { calcTotalHours } from '../../utils/dateUtils';
import { DefinitionsContext } from "../../context/DefinitionsContext";

const uData = [4000, 3000, 2000, 2780];
const xLabels = [
  'Total',
  'Approved',
  'Pending',
  'Denied',
];

const series = [
  {
    label: 'Total',
    data: [10],
  },
  {
    label: 'Pending',
    data: [10],
  },
  {
    label: 'Denied',
    data: [10],
  },
]

function makeDataPoint(id, value, label) {
  return {
    id: id,
    value: value,
    label: label,
  }
}

function TimeRequestBoard({ times }) {
  const { translationList } = useContext(DefinitionsContext);
  const { palette, container } = useTheme();
  const [totalHours, setTotalHours] = useState('0:00');
  const [data, setData] = useState([]);
  useEffect(() => {
    if (times) {
      setTotalHours(calcTotalHours(times));
      const total = calcTotalHours(times);
      const ta = calcTotalHours(times.filter(el => el.approval.status.name === 'approved'));
      const td = calcTotalHours(times.filter(el => el.approval.status.name === 'denied'));
      const tp = calcTotalHours(times.filter(el => el.approval.status.name === 'pending'));
      const tmp = []
      if (ta !== '0.00') { tmp.push({ total: `${ta}h`, value: ta, color: palette.success.main, label: translationList.approved }) }
      if (td !== '0.00') { tmp.push({ total: `${td}h`, value: td, color: palette.error.main, label: translationList.denied }) }
      if (tp !== '0.00') { tmp.push({ total: `${tp}h`, value: tp, color: palette.grey[600], label: translationList.pending }) }
      setData(tmp);
    }
  }, [times]);

  return (
    <Box
      sx={{
        padding: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '156px',
      }}
    >
      {times.length === 0 ? (
        <Typography variant='h6'
                    sx={{ flexGrow: 1,
                      textAlign: 'center',
                      '@media (max-width:600px)': {
                        fontSize: '15px',
                      }
                    }}
        >{translationList.dontHaveHoursMonth}</Typography>
      ) : (
        <PieChart
          series={[
            {
              arcLabel: (item) => `${item.value}h`,
              arcLabelMinAngle: 5,
              data: data,
              innerRadius: 30,
              outerRadius: 100,
              paddingAngle: 5,
              cornerRadius: 5,
              startAngle: -90,
              endAngle: 90,
              cx: 250,
              cy: 170,
            },
          ]}
          slotProps={{
            legend: {
              hidden: false,
              direction: 'column',
              padding: { top: 0, right: 0, bottom: 26, left: 0 },
              position: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            }
          }}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontWeight: 'bold',
              '@media (max-width:600px)': {
                fontSize: '20px',
              },
            },
          }}
      width={500}
      height={200}
        />
      )
      }
    </Box>
  );
}

export { TimeRequestBoard }
export default TimeRequestBoard;