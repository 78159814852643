import React, {useState, useEffect, useContext} from 'react';
import { useTheme } from '@emotion/react';
import {
  Box,
  Paper,
  Button,
  ButtonBase,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material';
import ImageUploading from 'react-images-uploading';
import Resizer from 'react-image-file-resizer';
import { CloseButton } from '../ui/buttons';
import partyImage from '../../assets/party_default_image256.jpg'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {DefinitionsContext} from "../../context/DefinitionsContext";

function ImageUpload({onUpload, defaultImage=null, disabled=false, maxNumber=1}) {
  const [images, setImages] = useState();
  const [width, height] = ['120px', '120px'];
  const { palette: {grey} } = useTheme();
    const { translationList } = useContext(DefinitionsContext);

  useEffect( () => {
    async function loadImage(b64Image) {
      const res = await fetch(b64Image)
      const blob = await res.blob();
      const file = new File([blob], "File Name", {type: "image/jpeg"});
      setImages( [{data_url: b64Image, file: file}] );
    }
    if (defaultImage) {
      loadImage(defaultImage);
    }
  }, [defaultImage]);

  function onChange(imageList, addUpdatedIndex) {
    setImages(imageList);
    handleUpload(imageList[addUpdatedIndex]);
  }

  function handleUpload(uploadedImage) {
    if (uploadedImage.file) {
      // Resize the image and convert it to base64
      Resizer.imageFileResizer(
        uploadedImage.file,
        300, // Set the desired width (you can adjust it as needed)
        300, // Set the desired height
        'JPEG', // Set the desired format (you can use 'JPEG', 'PNG', etc.)
        100, // Set the quality of the resized image
        0, // Rotation (you can set this to 0 for no rotation)
        (base64Image) => {
          // Handle the base64 image string
          // Now you can use the base64Image as needed (e.g., save it in state)
          onUpload(base64Image);
        },
        'base64' // Specify the output type
      );
    }
  }

  return (
    <Box>
      <ImageUploading
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey='data_url'
        acceptType={['jpg', 'png']}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          <Box
          > 
            {(!images)
              ? <Button
                  onClick={onImageUpload}
                  disabled={disabled}
                  variant='outlined'
                  sx={{
                    color: grey[100],
                    borderColor: grey[700],
                    width: width,
                    height: height,
                  }}
                  {...dragProps}
                >
                    {translationList.uploadImage}
                </Button>
              : (<ImagePreview imageList={imageList} disabled={disabled} onImageUpdate={onImageUpdate}/>)
            }
        </Box>
        )}
      </ImageUploading>
    </Box>
  );
}

function ImagePreview({imageList, disabled, onImageUpdate}) {
  const { palette: {grey} } = useTheme();
  return (
    <Box
      sx={{
        width: '120px',
        height: '120px',
        border: 1,
        borderColor: grey[700],
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      {imageList.map((image, index) => (
        <ButtonBase
          key={index}
          onClick={ () => onImageUpdate(index)}
          disabled={disabled}
          sx={{
            width: '100%',
            height: '100%'
          }}
        >
          <Box
            component='img'
            sx={{
              width: 'auto',
              height: '100%',
              objectFit: 'cover',
            }}
            src={image.data_url}
            alt=''
          />
        </ButtonBase>
        )
      )}
    </Box>
  )
}

export { ImageUpload }
export default ImageUpload;