import React, { useState, useContext } from 'react';
import {
  Box,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
} from '@mui/material';
import { CurrentUserContext } from '../../context/CurrentUserContext';

function PermissionSelect({ permission, setPermission }) {
  const { currentUser } = useContext(CurrentUserContext);
  const toFilter = ['MANAGER', 'EXECUTIVE'];
  const options = getOptions().filter( option => !toFilter.includes(option) );

  function getOptions() {
    const options = ['EMPLOYEE']
    const role = currentUser?.account?.roleName;
    if (!role) {return;}
    if ([ 'MANAGER', 'EXECUTIVE', 'ADMIN'].includes(role)) {
      options.push('MANAGER');
    }
    if ([ 'EXECUTIVE', 'ADMIN'].includes(role)) {
      options.push('EXECUTIVE');
    }
    if ([ 'ADMIN'].includes(role)) {
      options.push('ADMIN');
    }
    return options;
  }

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel>Permission</InputLabel>
        <Select
          label="Permission"
          value={permission}
          onChange={e => setPermission(e.target.value)}
        >
          {options.map( option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  );
}

export { PermissionSelect }
export default PermissionSelect;