import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { CurrentUserContext } from '../../context/CurrentUserContext';
import {DefinitionsContext} from "../../context/DefinitionsContext";

function ErrorFallback({ error }) {
  const { translationList } = useContext(DefinitionsContext);
  const navigate = useNavigate();
  const { setCurrentUser } = useContext(CurrentUserContext);
  const location = useLocation();
  const errorMessage = location.state?.message || 'An unexpected error occurred.';
  console.log(errorMessage)

  useEffect(() => {
    (async () => {
      await new Promise(resolve => setTimeout(resolve, 3000));
      setCurrentUser(null);
      localStorage.removeItem('currentUser');
      navigate('/login', { replace: true });
    })();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        padding: '1rem',
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
          Something went wrong. Redirecting to login...
      </Typography>
      <Typography variant="body1" component="p" gutterBottom>
        {errorMessage}
      </Typography>
    </Box>
  );
}

export { ErrorFallback };
export default ErrorFallback;