import React from 'react';
import { PostBrowser } from '.';


function NewsFeed() {
  return(
    <PostBrowser topicId={1}/>
  )
}

export default NewsFeed;