import React, { useRef } from 'react';
import {
  Box,
  Modal,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialoogActions,
  DialogContentText,
} from '@mui/material';
import { useTheme } from '@mui/material';
import Draggable from 'react-draggable';

import { DialogCloseButton } from '../buttons';
import { backdropProp, sxModal } from '../../../styles/styles';
import { DialogContainer } from '../containers';

function DraggablePaper(props) {
  const nodeRef = useRef(null);
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper 
        ref={nodeRef}
        {...props}
      />
    </Draggable>
  )
}

function SecondaryWindow({ title, isOpen, onClose, children, variant='primary', hideBackdrop=false, sx={} }) {
  const { container: {dialog} } = useTheme();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperComponent={DraggablePaper}
      PaperProps={variant === 'secondary' ? dialog.paper2 : dialog.paper}
      hideBackdrop={hideBackdrop}
      slotProps={dialog.slotProps}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 30px',
        }}
      >
        <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
          {title}
        </DialogTitle>
        <DialogCloseButton onClose={onClose}/>
      </Box>
      <DialogContent>
        {children} 
      </DialogContent>
    </Dialog>
  );
}

export { SecondaryWindow }
export default SecondaryWindow;