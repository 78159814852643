import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  useMediaQuery,
} from '@mui/material';
import {
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  enUS, frFR, deDE
} from '@mui/x-data-grid';
import { WorkFunctionAvatar } from '../work-functions/WorkFunctionItem';
import { UserCreator } from './UserCreator';

import { getAccount, listUsers } from '../../services';
import { UserEditor } from './UserEditor';
import { PersonalDataPopup } from '../personal-data/PersonalDataPopup';
import { DefinitionsContext } from "../../context/DefinitionsContext";
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import AccountIcon from '@mui/icons-material/ManageAccounts';
import { CurrentUserContext } from '../../context/CurrentUserContext';
import { Permission } from '../../components/wrapper/Permission';
import { isSameOrHigherRole, isSameOrHigherRoleWithStupidAdminOverwrite } from '../../components/wrapper/Permission';
import useViewportHeight from "../../styles/UseViewportHeight";

function getLocaleText(languageId) {
  switch (languageId) {
    case 1:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
    case 2:
      return deDE.components.MuiDataGrid.defaultProps.localeText;
    case 3:
      return frFR.components.MuiDataGrid.defaultProps.localeText;
    default:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
  }
}

function UserManagement() {
  const { currentUser } = useContext(CurrentUserContext);
  const { translationList } = useContext(DefinitionsContext);
  const [users, setUsers] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalAccount, setModalAccount] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [updateCounter, setUpdateCounter] = useState(0);
  const localeText = getLocaleText(currentUser.languageId);
  const vh = useViewportHeight();
  const isMobile = useMediaQuery('(max-width:600px)');

  const columns = [
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 100,
      cellClassName: 'actions',
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Permission level={3}>
            <GridActionsCellItem
              icon={<InfoIcon />}
              label={'Personal Info'}
              onClick={() => handleInfoClick(params.row.id)}
            />
          </Permission>
          <Permission level={7}>
            <GridActionsCellItem
              icon={<EditIcon />}
              label={'Edit Personal Info'}
              onClick={() => handleEditClick(params.row.id)}
              disabled={isSameOrHigherRole(params.row.account?.roleName, currentUser) && !(currentUser.id === params.row.id)}
            />
          </Permission>
          <Permission level={5}>
            <GridActionsCellItem
              icon={<AccountIcon />}
              label={translationList.edit}
              onClick={() => handleAccountClick(params.row.id)}
              disabled={isSameOrHigherRoleWithStupidAdminOverwrite(params.row.account?.roleName, currentUser) && !(currentUser.id === params.row.id)}
            />
          </Permission>
        </Box>
      ),
    },
    { field: 'firstName', headerName: translationList.firstname, width: isMobile ? 90 : 150 },
    { field: 'lastName', headerName: translationList.lastname, width: isMobile ? 90 : 150  },
    { field: 'userFunctions', headerName: translationList.functions, width: isMobile ? 150 : 100, renderCell: params => <WorkFunctionAvatar workFunctions={params.row.userFunctions} max={3} /> },
    { field: 'userFunctionNames', headerName: translationList.funtionNames, width: 110, valueGetter: params => params.row.userFunctions.map(el => el.name) },
    { field: 'activeFlag', headerName: 'Active', width: 110 },
    { field: 'roleName', headerName: 'Role', width: isMobile ? 90 : 110, renderCell: params => params.row.account?.roleName },

  ];

  useEffect(() => {
    (async () => {
      const newUsers = await listUsers();
      if (!newUsers) { return; }
      for (const el of newUsers) {
        el.account = (await getAccount(el.id))?.data;
      }
      setUsers(newUsers);
    })();
  }, [updateCounter])

  const handleInfoClick = (userId) => {
    // Handle the edit action here. You can navigate to an edit page or show a modal, for example.
    setSelectedUser(userId);
    setModalInfo(true);
  };

  const handleEditClick = (userId) => {
    // Handle the edit action here. You can navigate to an edit page or show a modal, for example.
    setSelectedUser(userId);
    setModalEdit(true);
  };

  const handleAccountClick = (userId) => {
    // Handle the edit action here. You can navigate to an edit page or show a modal, for example.
    setSelectedUser(userId);
    setModalAccount(true);
  };


  return (
    <Fragment>
      <UserCreator
        isOpen={modalCreate}
        onClose={() => setModalCreate(false)}
        onChange={() => setUpdateCounter(updateCounter+1)}
      />
      <UserEditor
        userId={selectedUser}
        isOpen={modalAccount}
        onClose={() => { setModalAccount(false); setUpdateCounter(updateCounter+1); }}
      />
      <PersonalDataPopup
        userId={selectedUser}
        isOpen={modalInfo}
        onClose={() => { setModalInfo(false)}}
        disabled={true}
      />
      <PersonalDataPopup
        userId={selectedUser}
        isOpen={modalEdit}
        onClose={() => {setModalEdit(false); setUpdateCounter(updateCounter+1); }}
        disabled={false}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Box sx={{
          height: `${vh * 0.85}px`,
          width: '100%',
            '@media (max-width:600px)': {
              height: `${vh * 0.8}px`,
              paddingRight: 1,
              paddingLeft: 1,
            },
          }}
        >
          <DataGrid
              sx={{
                '@media (max-width:600px)': {
                  border: 'none'
                },
              }}
            localeText={localeText}
            rows={users}
            columns={columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  userFunctionNames: false,
                  phoneNumber: false,
                  email: false,
                  activeFlag: false,
                },
              },
              filter: {
                filterModel: {
                  items: [{field: 'activeFlag', operator: 'equals', value: 'true'}],
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[20]}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            '@media (max-width:600px)': {
              justifyContent: 'center',
            },
          }}
        >
          <Permission level={5}>
            <Button
                sx={{
                  '@media (max-width:600px)': {
                    minWidth: '250px',
                  },
                }}
              variant='contained'
              onClick={() => setModalCreate(true)}
            >
              {translationList.newUser}
            </Button>
          </Permission>
        </Box>
      </Box>
    </Fragment>
  );
}

export { UserManagement };
export default UserManagement;