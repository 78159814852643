import React, {useContext, useState} from 'react';
import {
  Box,
  Chip,
  Avatar,
  AvatarGroup,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material';
import DoorIcon from '@mui/icons-material/SensorDoor';
import BarIcon from '@mui/icons-material/LocalBar';
import WarderobeIcon from '@mui/icons-material/Checkroom';
import RegisterIcon from '@mui/icons-material/PointOfSale';
import LightIcon from '@mui/icons-material/Light';
import SoundIcon from '@mui/icons-material/MicExternalOn';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import CleaningIcon from '@mui/icons-material/CleaningServices';
import AdvertisingIcon from '@mui/icons-material/Visibility';
import PostersIcon from '@mui/icons-material/Announcement';
import BookingIcon from '@mui/icons-material/StarOutline';
import BillingIcon from '@mui/icons-material/Payment';
import AccountingIcon from '@mui/icons-material/AccountBalanceWallet';
import ManagerIcon from '@mui/icons-material/Person';
import ExecutiveIcon from '@mui/icons-material/SupervisorAccount';
import DirectionIcon from '@mui/icons-material/Movie';

import { capitalize } from '../../utils';
import {DefinitionsContext} from "../../context/DefinitionsContext";



const workFunMap = {
  'door': {
    'icon': <DoorIcon key='door'/>,
    'title': 'door',
    'id': 1,
  },
  'register': {
    'icon': <RegisterIcon key='register'/>,
    'title': 'Register',
    'id': 2,
  },
  'wardrobe': {
    'icon': <WarderobeIcon key='warderobe'/>,
    'title': 'Warderobe',
    'id': 3,
  },
  'sound': {
    'icon': <SoundIcon key='sound'/>,
    'title': 'Sound',
    'id': 4,
  },
  'light': {
    'icon': <LightIcon key='light'/>,
    'title': 'Light',
    'id': 5,
  },
  'bar': {
    'icon': <BarIcon key='bar'/>,
    'title': 'Bar',
    'id': 12,
  },
  'cleaning': {
    'icon': <CleaningIcon key='cleaning'/>,
    'title': 'Cleaning',
    'id': 6,
  },
  'advertising': {
    'icon': <AdvertisingIcon key='advertising'/>,
    'title': 'Advertising',
    'id': 7,
  },
  'posters': {
    'icon': <PostersIcon key='posters'/>,
    'title': 'Posters',
    'id': 8,
  },
  'booking': {
    'icon': <BookingIcon key='booking'/>,
    'title': 'Booking',
    'id': 9,
  },
  'billing': {
    'icon': <BillingIcon key='billing'/>,
    'title': 'Billing',
    'id': 10,
  },
  'accounting': {
    'icon': <AccountingIcon key='accounting'/>,
    'title': 'Accounting',
    'id': 11,
  },
  'manager': {
    'icon': <ManagerIcon key='accounting'/>,
    'title': 'Manager',
    'id': 12,
  },
  'executive': {
    'icon': <ExecutiveIcon key='accounting'/>,
    'title': 'Executive',
    'id': 13,
  },
  'direction': {
    'icon': <DirectionIcon key='accounting'/>,
    'title': 'Direction',
    'id': 14,
  },
  'none': {
    'icon': <NoAccountsIcon />,
    'title': 'None',
  },
};

const errorFuns = {
  'none': {
    'icon': <NoAccountsIcon />,
    'title': 'None',
    'id'   :  -1,
  },
  'unknown': {
    'icon': <QuestionMarkIcon />,
    'title': 'Unknown',
    'id'   : -2,
  },
  'error': {
    'icon': <ErrorIcon />,
    'title': 'Error',
    'id'   : -3,
  }
}
function getUserFunctionFromObject(workFunction) {
  try {
    if (!workFunMap.hasOwnProperty(workFunction.name)) {
      return {...errorFuns.unknown, title: capitalize(workFunction.name)};
    }
    return workFunMap[workFunction.name]
  } catch(e) {
    return errorFuns.error;
  }
}

function getUserFunctionsFromList(workFunctions) {
  const tmp = [];
  if (workFunctions.length === 0) {
    return [ {...errorFuns.none, title: 'None'}];
  }
  for (let el of workFunctions) {
    tmp.push( getUserFunctionFromObject(el) )
  }

  return tmp;
  }

function getFirstUserFunctionFromName(functionName) {
  if (!workFunMap.hasOwnProperty(functionName)) {
    return errorFuns.unknown;
  }

  return workFunMap[functionName] ;
}

function WorkFunctionChip({workFunction}) {
  const { translationList } = useContext(DefinitionsContext);
  const funObj = getFirstUserFunctionFromName(workFunction);

  return (
    <Chip 
      variant='outlined'
      icon={funObj.icon} 
      label={translationList[funObj.title.toLowerCase()]}
    />
  );
}

function WorkFunctionIcon({ workFunctions }) {
  const { icon } = getUserFunctionsFromList(workFunctions)[0];
  return (
    icon
  );
}


function WorkFunctionAvatar({ workFunctions, max=5, size='32px' }) {
  const { palette } = useTheme();
  const funs = getUserFunctionsFromList(workFunctions);

  return(
    <AvatarGroup
      max={max}
      slotProps={{
        additionalAvatar: {
          sx: {
            bgcolor: palette.primary.main,
            width: size,
            height: size,
          },
        },
      }}
    >
      {funs.map( el => (
      <Avatar
        key={el.id}
        sx={{
          bgcolor: palette.primary.main,
          //color: palette.secondary.main,
          width:  size,
          height: size,
          '& .MuiSvgIcon-root': {
          }
        }}
      >
        {el.icon}
      </Avatar>
      ) )}
    </AvatarGroup>
  );
}

export { WorkFunctionChip, WorkFunctionIcon, WorkFunctionAvatar }
export default WorkFunctionChip;