import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { CurrentUserContext } from '../../context/CurrentUserContext';

import * as api from '../../services'

import { DateRangeListContainer } from '../../components/ui/containers';
import TimeRequestList from './TimeRequestList';
import TimeRequestCreator from './TimeRequestCreator';
import TimeRequestBoard from './TimeRequestBoard';
import TimeRequestDetails from './TimeRequestDetails';
import { initDateRange } from '../../utils/dateUtils';
import { SecondaryWindow } from '../../components/ui/windows';
import useViewportHeight from "../../styles/UseViewportHeight";

function TimeRequestBrowser() {
  const { currentUser } = useContext(CurrentUserContext);
  const [times, setTimes] = useState([]);
  const [isCreator, setIsCreator] = useState(false);
  const [dateRange, setDateRange] = useState(initDateRange());
  const [modalDetail, setModalDetail] = useState(false);
  const [detail, setDetail] = useState('');
  const vh = useViewportHeight();

  useEffect(() => { if (detail !== '') {setModalDetail(true);} }, [detail]);
  useEffect(() => { if (modalDetail === false) {setDetail('');} }, [modalDetail]);

  useEffect(() => {
    update();
  }, [currentUser]);

  useEffect(() => {
    if (dateRange) {
      (async () => {
        await update();
      })();
    }
  }, [dateRange]);

  async function update() {
    const res = await api.getUserTimes(currentUser.id, dateRange);
    if (!res?.data) { return; }

    const tmpTimes = [];
    for (let timeRequest of res.data) {
      const res2 = await api.getApproval(timeRequest.id);
      if (!res2) { continue; }
      tmpTimes.push({ ...timeRequest, approval: res2 });
    }
    setTimes(tmpTimes);
  }

  return (
    <Fragment>
      <TimeRequestCreator
        isOpen={isCreator}
        onUpdate={update}
        onClose={() => setIsCreator(false)}
      />
      <SecondaryWindow
        title='Time Request Details'
        isOpen={modalDetail}
        onClose={() => setModalDetail(false)}
        text={detail}
      >
        <TimeRequestDetails data={detail} />
      </SecondaryWindow>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <TimeRequestBoard times={times} />
        <DateRangeListContainer
          onAdd={() => setIsCreator(true)}
          onRangeChange={(t0, t1) => setDateRange([t0, t1])}
          sx={{
              height: `${vh * 0.65}px`,
              overflowY: times.length > 9 ? 'auto' : 'visible',
              '@media (max-width:600px)': {
                  height: `${vh * 0.45}px`,
                  overflowY: times.length > 4 ? 'auto' : 'visible',

              }
          }}
        >
          <TimeRequestList times={times} onDetail={(time) => setDetail(time)} />
        </DateRangeListContainer>
      </Box>
    </Fragment>
  );
}

export { TimeRequestBrowser }
export default TimeRequestBrowser;