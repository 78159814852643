import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Paper,
  Button,
  TextField,
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@mui/material';

function FormRadioInput({ name, control, label, defaultValue, disabled, preselect=null, entries=[], type='text', sx={}}) {
  return(
    <Box
      sx={{
        flex: 1,
        ...sx,
      }}
    >
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <FormControl disabled={disabled}>
          <FormLabel filled={true}>{label}</FormLabel>
          <RadioGroup
            row
            defaultValue={preselect}
            value={value}
            onChange={onChange}
          >
            { entries.map( el => <FormControlLabel value={el.value} label={el.label} key={el.label} control={<Radio />} />)}
          </RadioGroup>
        </FormControl>
      )
    }
    />
    </Box>
  )
}

export { FormRadioInput };
export default FormRadioInput;
