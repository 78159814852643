import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import PasswordChecklist from 'react-password-checklist';

import { CurrentUserContext } from '../../context/CurrentUserContext';
import { Notification } from '../../components/ui/Notification';
import * as api from '../../services';
import { DefinitionsContext } from '../../context/DefinitionsContext';

function PasswordChanger({ user }) {
  const { currentUser } = useContext(CurrentUserContext);
  const [old, setOld] = useState('');
  const [new1, setNew1] = useState('');
  const [new2, setNew2] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [notify, setNotify] = useState({ text: '', severity: 'info' });
  const [isNotify, setIsNotify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [wasPasswordChanged, setWasPasswordChanged] = useState(false);
  const { translationList } = useContext(DefinitionsContext);

  useEffect(() => {
    if (currentUser?.account?.isPasswordChangeRequired) {
      setNotify({
        text: translationList.passwordChangeRequired,
        severity: 'warning'
      });
      setIsNotify(true);
    }
  }, [currentUser, translationList]);

  async function handleSubmit() {
    try {
      setIsLoading(true);
      const res = await api.changePassword(old, new1, new2);
      setWasPasswordChanged(true);
      setIsLoading(false);
      if (res.status === 200) {
        setNotify({ text: translationList.passwordChanged || 'Password changed, you need to log in again.', severity: 'success' });
        setIsNotify(true);
        setTimeout(() => {
          api.logout();
          localStorage.removeItem('currentUser');
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 400) {
        setNotify({ text: translationList.currentPasswordIncorrect, severity: 'error' });
      } else if (error.response.status === 409) {
        setNotify({ text: translationList.newPasswordSameAsCurrent, severity: 'error' });
      } else {
        setNotify({ text: translationList.errorOccurred || 'An error occurred.', severity: 'error' });
      }
      setIsNotify(true);
    }
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default form submission behavior
      if (!isLoading && !wasPasswordChanged && isValid) {
        handleSubmit();
      }
    }
  }

  return (
      <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
          onKeyUp={handleKeyPress}
      >
        <Notification
            text={notify.text}
            severity={notify.severity}
            isOpen={isNotify}
            onClose={() => setIsNotify(false)}
        />
        <Typography variant='h6'>{translationList.changePassword}</Typography>
        <TextField
            label={translationList.currentPassword}
            type='password'
            value={old}
            onChange={e => setOld(e.target.value)}
        />
        <TextField
            label={translationList.newPassword}
            type='password'
            value={new1}
            onChange={e => setNew1(e.target.value)}
        />
        <TextField
            label={translationList.repeatNewPassword}
            type='password'
            value={new2}
            onChange={e => setNew2(e.target.value)}
        />
        {(new1 !== '' || new2 !== '') &&
            <PasswordChecklist
                rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
                minLength={8}
                value={new1}
                valueAgain={new2}
                onChange={val => setIsValid(val)}
                messages={{
                  minLength: translationList.passwordMinLength,
                  specialChar: translationList.passwordSpecialChar,
                  number: translationList.passwordNumber,
                  capital: translationList.passwordCapital,
                  match: translationList.passwordMatch
                }}
            />
        }
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          {isLoading ? (
              <CircularProgress />
          ) : (
              <Button
                  disabled={!isValid || wasPasswordChanged}
                  variant='contained'
                  onClick={handleSubmit}
              >
                {translationList.submit}
              </Button>
          )}
        </Box>
      </Box>
  );
}

export { PasswordChanger };
export default PasswordChanger;
