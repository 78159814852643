import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import {
  MobileDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import dayjs from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import EventCaldendar from '../../../features/events/EventCalendar';
import { dateToRange } from '../../../utils/dateUtils';

function DateRange({ markedDays, selectedDate, onDateSelect, onRangeChange }) {
  if (selectedDate) {
    return (
      <DateRangeControlled
        markedDays={markedDays}
        selectedDate={selectedDate}
        onDateSelect={onDateSelect}
        onRangeChange={onRangeChange}
      />)
    } else {
    return (
      <DateRangeUncontrolled
        markedDays={markedDays}
        onRangeChange={onRangeChange}
      />
      )
    }
  }

function DateRangeControlled({ markedDays, selectedDate, onDateSelect, onRangeChange }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <IconButton
        onClick={ () => {
          const [startDate, endDate] = dateToRange( selectedDate.add(-1, 'month') );
          onRangeChange(startDate, endDate);
          onDateSelect( selectedDate.add(-1, 'month') );
        } }
      >
        <NavigateBeforeIcon />
      </IconButton>
      <EventCaldendar
        selectedDate={selectedDate}
        onDateSelect={onDateSelect}
        onRangeChange={onRangeChange}
        markedDays={markedDays}
      />
      <IconButton
        onClick={ () => {
          const [startDate, endDate] = dateToRange( selectedDate.add(1, 'month') );
          onRangeChange(startDate, endDate);
          onDateSelect( selectedDate.add(1, 'month') );
        } }
      >
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
}

function DateRangeUncontrolled({ markedDays, onRangeChange }) {
  const [selectedDate, setSelectedDate] = useState( dayjs() );
  useEffect( () => {
  }, [selectedDate])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <IconButton
          onClick={ () => {
            const [startDate, endDate] = dateToRange( selectedDate.add(-1, 'month') );
            onRangeChange(startDate, endDate);
            setSelectedDate( selectedDate.add(-1, 'month') );
          } }
        >
          <NavigateBeforeIcon />
        </IconButton>
      </Box>

      <EventCaldendar
        selectedDate={selectedDate}
        onDateSelect={ newDate => setSelectedDate(newDate) }
        onRangeChange={onRangeChange}
        markedDays={markedDays}
      />

      <Box>
        <IconButton
          onClick={ () => {
            const [startDate, endDate] = dateToRange( selectedDate.add(1, 'month') );
            onRangeChange(startDate, endDate);
            setSelectedDate( selectedDate.add(1, 'month') );
          } }
        >
          <NavigateNextIcon />
        </IconButton>
      </Box>

    </Box>
  );
}

export { DateRange }
export default DateRange;