import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { CurrentUserContext } from '../../context/CurrentUserContext';
import { hasPermission } from '../../components/wrapper/Permission';

const ProtectedRoute = ({ children, level }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const location = useLocation();

  // Redirect to login if not authenticated
  if (!currentUser) {
    console.warn('No current user in protected route');
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // Redirect to "not authorized" page if permissions are insufficient
  if (!hasPermission(level, currentUser)) {
    return <Navigate to="/not-authorized" />;
  }

  return children;
};

export default ProtectedRoute;