import React, {useContext, useEffect, useState} from 'react';
import {
  Box,
  Fab,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
} from '@mui/material';
import { useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LayersIcon from '@mui/icons-material/Layers';
import PlanIcon from '@mui/icons-material/PermContactCalendar';
import {DefinitionsContext} from "../../../context/DefinitionsContext";

function AddFab({ onClick, color='primary', sx={} }) {
  return (
    <Fab
      color={color}
      onClick={onClick}
      sx={{
        ...sx,
      }}
    >
      <AddIcon />
    </Fab>
  );
}
function ActionDial({ onAction, hideActions=[] }) {
  const { translationList } = useContext(DefinitionsContext);
  const { palette } = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const allActions = [
    { icon: <InfoIcon   sx={{}}/>, name: 'Info' },
    { icon: <PlanIcon   sx={{}}/>, name: 'Plan' },
    { icon: <EditIcon   sx={{}}/>, name: 'Edit' },
    { icon: <SaveIcon   sx={{}}/>, name: 'Save' },
    { icon: <DeleteIcon sx={{}}/>, name: 'Delete' },
  ];
  const [actions, setActions] = useState(allActions.filter(action => !hideActions.includes(action.name)));

  useEffect(() => {
    setActions(allActions.filter(action => !hideActions.includes(action.name)));
  }, [hideActions]);

  function handleClick(action) {
    onAction[action.name]();
    setIsOpen(false);
  }
  return (
    <Box sx={{ height: 56, transform: 'translateY(-0px)', flexGrow: 1 }}>
      <SpeedDial
        ariaLabel='ActionDial'
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<LayersIcon />}
        onOpen={ () => setIsOpen(true) }
        onClose={ () => setIsOpen(false) }
        open={isOpen}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={translationList[action.name.toLowerCase()]}
            tooltipOpen
            onClick={ () => handleClick(action) }
          />
        ))}
      </SpeedDial>
    </Box>
  );
}

export { AddFab, ActionDial }
export default AddFab;