import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
} from '@mui/material';

function PageContainer({ children }) {
  return (
    <Paper
      elevation={1}
      name='page-container'
      sx={{
        width: '100%',
        height: '100%',
        gap: 2,
        borderRadius: 0,
      }}
    >
      {children}
    </Paper>
  );
}

export { PageContainer };
export default PageContainer;