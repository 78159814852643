import { api, defaultCallback } from './api';

async function addTicket(data, cb=defaultCallback) {
  const res = await api.post(
    `ticket/`,
    data
  );

  cb(res);
  return res;
}

async function getTicket(eventId, ticketTypeId=null, cb=defaultCallback) {
  const params = {eventId: eventId};
  if (ticketTypeId) {params.ticketTypeId = ticketTypeId};
  const res = await api.get(
    `ticket`,
    {
      params: params,
    }
  );

  cb(res);
  return res;
}

async function getTicketById(ticketId, cb=defaultCallback) {
  const res = await api.get(
    `ticket/${ticketId}`
  );

  cb(res);
  return res;
}

async function updateTicket(ticketId, data, cb=defaultCallback) {
  const res = await api.put(
    `ticket/${ticketId}`,
    data
  );

  cb(res);
  return res;
}

async function deleteTicket(ticketId, cb=defaultCallback) {
  const res = await api.delete(
    `ticket/${ticketId}`
  );

  cb(res);
  return res;
}

export {
  addTicket,
  getTicket,
  updateTicket,
  deleteTicket,
}