import React, { useState } from 'react';
import {
  Paper,
  Button,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Notification } from '../../components/ui/Notification';
import { useTheme } from '@emotion/react';
import { login, sendResetPasswordMail } from '../../services/authAPI';
import logo from '../../assets/logo_alpha.png';

import { set } from 'react-hook-form';

export default function Login({ onLogin }) {
  const { palette } = useTheme();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [notify, setNotify] = useState({ text: '', severity: 'info' });
  const [showNotify, setShowNotify] = useState(false);
  const [failedLogin, setFailedLogin] = useState(false);
  const [loading, setLoading] = useState(false);

  async function loginUser() {
    setLoading(true);
    try {
      const res = await login(username, password)
      if (!res?.data) { throw new Error('No data received'); }
      onLogin(res.data);
    } catch (error) {
      if (!error.response || !error.response?.status) {
        setNotify({ text: 'An error occurred', severity: 'error' });
        setShowNotify(true);
        return;
      }
      if (error.response.status === 401 || error.response.status === 403) {
        switch (error.response.data?.error) {
          case 'archived':
            setFailedLogin(true);
            setNotify({ text: 'User is archived', severity: 'error' });
            setShowNotify(true);
            break;
          case 'revoked':
            setFailedLogin(true);
            setNotify({ text: 'Token revoked. Try to clear your browser cache', severity: 'error' });
            setShowNotify(true);
            break;
          default:
            setFailedLogin(true);
            setNotify({ text: 'Wrong username or password', severity: 'error' });
            setShowNotify(true);
        }
      }
    }
    finally {
      setLoading(false);
    }
  };

  async function handleForgotPassword() {
    try {
      const res = await sendResetPasswordMail(username);
      setNotify({ text: res.data, severity: 'success' });
      setShowNotify(true);
    } catch (error) {
      if (error?.response?.data?.error === 'wrong_username') {
        setNotify({ text: `${error.response.data.message}\nPlease enter your username, then press the button.`, severity: 'error' });
      } else {
        setNotify({ text: 'An error occurred', severity: 'error' });
      }
      setShowNotify(true);
    }
  }

  // Function to handle "Enter" key press
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      loginUser();
    }
  };

  return (
    <Paper
      component='form'
      noValidate
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        width: '100%',
        maxWidth: '300px',
        margin: 'auto',
        padding: '1rem',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <img
          style={{ maxHeight: '256px', maxWidth: '100%', width: 'auto', height: 'auto' }}
          src={logo}
          alt="Logo"
        />
      </div>
      <Notification
        text={notify.text}
        severity={notify.severity}
        isOpen={showNotify}
        onClose={() => setShowNotify(false)}
      />
      <TextField
        label='Email'
        id='user-name'
        fullWidth
        value={username}
        onChange={e => setUsername(e.target.value)}
        onKeyUp={handleKeyPress}
      />
      <TextField
        label='Password'
        id='password'
        type='password'
        fullWidth
        value={password}
        onChange={e => setPassword(e.target.value)}
        onKeyUp={handleKeyPress}
      />
      <Button
        variant='contained'
        onClick={loginUser}
        disabled={loading}
        startIcon={loading ? <CircularProgress size={20} /> : null}
      >
        Sign In
      </Button>
      <Button
        size='small'
        onClick={handleForgotPassword}
      >
        Forgot Password
      </Button>
    </Paper>
  );
}