import React, { useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';

function Loadable({isLoading, children}) {
  if (isLoading) {
    return <CircularProgress />;
  }
  else {
    return children;
  }
}

export { Loadable }
export default Loadable;